/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import React from "react";
import {
  Container,
  FooterContainer,
  ContainerTiny,
  BottomMarkingTime,
  NewMarkingButton,
  ItemContainer,
  HeaderContainer,
  MarkingTime,
  HeaderTextContainer,
  MarkingTitle,
  MarkingDescription,
  IconsContainer,
  ContainerDelete,
} from "./styles";
import { IMark } from "./interface";
import { StatusContainer, StatusText } from "../styles";
import { markingService } from "../../../../services/marking";
import plusIcon from "../../../../assets/procedure/plus.svg";
import deleteIcon from "../../../../assets/hearingDetails/delete.svg";
import editIcon from "../../../../assets/hearingDetails/edit.svg";
import showIcon from "../../../../assets/hearingDetails/show.svg";
import hideIcon from "../../../../assets/hearingDetails/hide.svg";
import { useToast } from "../../../../hooks/toast";

const Mark: React.FC<IMark> = ({
  marking,
  onDeleteMarking,
  onEditMarking,
  setOpenModal,
  processCuts,
  data = [],
  currentIndex,
  setCurrentIndex,
  onClickRefreshStatusCuts,
  isBeginin,
}) => {
  const { addToast } = useToast();

  function retornarStatus(status: any) {
    switch (status) {
      case -1:
        return "Aguardando";
      case 0:
        return "Na fila";
      case 1:
        return "Carregando vídeo";
      case 2:
        return "Cortando vídeo";
      case 3:
        return "Cortado";
      case 4:
        return "Erro ao carregar video";
      case 5:
        return "Erro ao cortar video";
      case 6:
        return "Erro no upload do video";
      default:
        return "Aguardando";
    }
  }

  function secondsToTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + remainingSeconds).slice(-2);
    const timeString = formattedMinutes + ":" + formattedSeconds;
    return timeString;
  }

  const handleProcessOrReloadCuts = () => {
    if (hasErrorInCuts()) {
      reloadCuts();
    } else {
      processCuts();
    }
  };

  const hasErrorInCuts = () => {
    if (data.length > 0) {
      const hasErrors = data?.find((x) => x.status >= 4 && x.status <= 6);
      if (hasErrors) {
        return true;
      }
      return false;
    }
  };

  async function reloadCuts() {
    try {
      const listAux: Array<any> = [];
      data.forEach((element) => {
        listAux.push({ ...element, status: 0 });
      });

      for (let i = 0; i < listAux.length; i++) {
        await markingService.patch(
          listAux[i].id?.toString(),
          "Status",
          listAux[i].status?.toString()
        );
      }

      await refresgCuts();
    } catch {
      addToast({
        type: "error",
        title: "Erro ao fazer marcação",
        description: "Ocorreu um erro inesperado",
      });
      return false;
    }
  }

  async function refresgCuts() {
    onClickRefreshStatusCuts && (await onClickRefreshStatusCuts());
  }

  return (
    <Container>
      <ContainerTiny id="container_tiny">
        {data.length ? (
          data.map((item, index) => (
            <ItemContainer selected={index === currentIndex} key={item?.id}>
              <HeaderContainer>
                <MarkingTime
                  onClick={() => marking.toggleMarkingTime(item.offSet1)}
                >
                  {secondsToTime(item.offSet1)}-{secondsToTime(item.offSet2)}
                </MarkingTime>
                <HeaderTextContainer>
                  <MarkingTitle>{`${
                    item.attendantName
                      ? item.attendantName.split(" ")[0] + " - "
                      : ""
                  }${retornarStatus(item.status)}`}</MarkingTitle>

                  {(item.status === -1 || item.status === 3) && (
                    <IconsContainer>
                      {index === currentIndex && (
                        <ContainerDelete>
                          <img
                            alt=""
                            onClick={() => onEditMarking(item)}
                            src={editIcon}
                          />
                          <img
                            alt=""
                            onClick={() => onDeleteMarking(item)}
                            src={deleteIcon}
                          />
                        </ContainerDelete>
                      )}
                      <img
                        alt=""
                        onClick={() =>
                          setCurrentIndex(index === currentIndex ? -1 : index)
                        }
                        src={index === currentIndex ? hideIcon : showIcon}
                      />
                    </IconsContainer>
                  )}
                </HeaderTextContainer>
              </HeaderContainer>
              {index === currentIndex && (
                <MarkingDescription>{item.subject}</MarkingDescription>
              )}
            </ItemContainer>
          ))
        ) : (
          <StatusContainer>
            <StatusText>Ainda não foi feita nenhuma marcação</StatusText>
          </StatusContainer>
        )}
      </ContainerTiny>
      <FooterContainer>
        <BottomMarkingTime>
          {Boolean(data?.find((x) => x.status === -1)) || hasErrorInCuts() ? (
            <NewMarkingButton
              style={{
                background: "#6fab7f",
              }}
              onClick={handleProcessOrReloadCuts}
            >
              <strong>
                {hasErrorInCuts() ? "Reprocessar" : "Processar cortes"}
              </strong>
            </NewMarkingButton>
          ) : (
            <div />
          )}
          <NewMarkingButton
            style={{
              background: isBeginin ? "#1D9BF0" : "#f5c242",
            }}
            onClick={() => {
              if (marking.playerState.playbackRate > 1) {
                addToast({
                  type: "warning",
                  title: "Impossível fazer marcação",
                  description:
                    "Para fazer uma marcação é necessário o video está em velocidade 1x",
                });
              } else {
                if (marking.playerState.playing) {
                  marking.toggleVideoPlay();
                }
                setOpenModal(true);
              }
            }}
          >
            <img src={plusIcon} alt="" />{" "}
            <strong style={{ marginLeft: 10 }}>
              {isBeginin ? "Nova marcação" : "Finalizar"}
            </strong>
          </NewMarkingButton>
        </BottomMarkingTime>
      </FooterContainer>
    </Container>
  );
};

export default Mark;
