import { GeneralInput, SearchEmpty } from "@kentarepo/kcf-assets";
import { useEffect, useState } from "react";
import { SystemConfigurationService } from "../../../services/systemConfiguration";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { Grid } from "@mui/material";
import { ContainerLoading } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Loading from "react-loading";
import { updateGlobalConfigurationsAction } from "../../../store/modules/globalConfiguration/actions";

export interface IServiceIntegrationNotificationItem {
  hearingTypeId: number;
  hearingTypeName: string;
  active: boolean;
  attendantRoleList: Array<IDefaultObject>;
}

export interface IDefaultObject {
  ServiceIntegrationNotificationId?: number;
  id: number;
  name: string;
  active: boolean;
  attendantRoleId: number;
  hearingTypeId: number;
}

export interface IServiceIntegrationNotificationResponseAPI {
  customerId: number;
  hearingType: Array<IDefaultObject>;
  attendantRole: Array<IDefaultObject>;
  ServiceIntegrationNotification: Array<IServiceIntegrationNotificationItem>;
}

export const ServiceIntegrationNotification = ({
  inputValues,
  setInputValues,
  notify,
  hearingId
}: any) => {
  const [filterList, setFilterList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { userCustomerProfile } = useSelector((store: any) => store.profile);
  let customerId: any = null;
  const dispatch = useDispatch()
  const user = getValueFromlocalStorage("@Kenta:user");

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const getAllConfigurationsByCustomerId = async () => {
    setLoading(true);
    const { data } = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    dispatch(updateGlobalConfigurationsAction(data))

    const list = data.filter(
      (item: any) => item?.name === "ServiceIntegrationNotification"
    );
    setFilterList(list);

    const initialValues: { [key: string]: string } = {};
    list.forEach((item: any) => {
      const value = JSON.parse(item.value);
      value.parametros.forEach((param: any) => {
        initialValues[`${item?.name}-${param?.name}`] = param?.name;
      });
    });
    setInputValues(initialValues);
    setLoading(false);
  };

  useEffect(() => {
    getAllConfigurationsByCustomerId();
  }, []);

  return (
    <>
      {loading ? (
        <ContainerLoading>
          <Loading width={40} type="spin" color="#000" />
        </ContainerLoading>
      ) : notify ? (
        <Grid container spacing={3}>
          {filterList?.map((item: any, index: any) => {
            const value = JSON.parse(item.value);
            return (
              <Grid item xs={12} key={index}>
                {value.parametros.map((param: any, paramIndex: any) => {
                  const inputId = `${item?.name}-${param?.name}`;
                  if (param.label === "Código da Vara") {
                    inputValues[inputId] = userCustomerProfile.placeId;
                  }
                  if (param.label === "Codigo do Cliente") {
                    inputValues[inputId] = customerId;
                  }
                  if (param.label === "Código da audiência") {
                    inputValues[inputId] = hearingId;
                  }
                  return (
                    <Grid item xs={12} key={paramIndex}>
                      <GeneralInput
                        id={inputId}
                        error={false}
                        value={inputValues[inputId] || ""}
                        required
                        onChange={(e) =>
                          setInputValues((prevValues: any) => ({
                            ...prevValues,
                            [inputId]: e,
                          }))
                        }
                        name={param?.name}
                        label={param.label}
                        disabled={
                          param.label === "Codigo do Cliente" ||
                          param.label === "Código da Vara" ||
                          param.label === "Código da audiência"
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <SearchEmpty textMessage={"Sem permissão"} typeIcon={"avatar"} />
      )}
    </>
  );
};
