import React, { useEffect, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { LeftMenu } from "../../../features/menus/Left";
import { useSelector } from "react-redux";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

let listMenusOpen = ["cadastros", "configurações"];

interface MenuItem {
  id: string;
  menuTitle: string;
  locale: string;
  read: boolean;
  datacy: string;
}

export const Administration: React.FC = () => {
  const currentLocale = useLocation();
  const navigate = useNavigate();

  const { profileName } = useSelector((state: any) => state.profile);

  useEffect(() => {
    if (profileName === "Operador") {
      navigate("/audiences");
    } else if (currentLocale.pathname === "/administration") {
      navigate("/administration/Attendant");
    }
  }, [profileName]);

  const permissions = getValueFromlocalStorage("@Kenta:permissions");
  const parsedPermissions = permissions ? JSON.parse(permissions) : [];

  const mapPermissionsToMenu = (permissions: any[]): any[] => {
    const filteredPermissions = permissions.filter(
      (item) => item.menu === 'Administração' 
    );
    const cadastros: MenuItem[] = [];
    const configuracoes: MenuItem[] = [];

    filteredPermissions.forEach((item) => {
      const menuItem = {
        id: item.formId,
        menuTitle: item.description,
        locale: `/administration/${item.form}`,
        read: item.listObjectUserProfileActionDTOs.some(
          (action: { action: string }) => action.action === "Read"
        ),
        datacy: item.form.toLowerCase(),
      };

      if (item.url.toLowerCase().includes("cadastros")) {
        cadastros.push(menuItem);
      } else if (item.url.toLowerCase().includes("configurações")) {
        configuracoes.push(menuItem);
      }
    });

    cadastros.sort((a, b) => a.menuTitle.localeCompare(b.menuTitle));
    configuracoes.sort((a, b) => a.menuTitle.localeCompare(b.menuTitle));

    return [
      { title: "Cadastros", navigation: cadastros },
      { title: "Configurações", navigation: configuracoes },
    ];
  };

  const formattedPermissions = useMemo(
    () => mapPermissionsToMenu(parsedPermissions),
    [parsedPermissions]
  );
  
  return (
    <LeftMenu
      currentLocale={currentLocale.pathname}
      type="administration"
      menuTitle="Administração"
      data-cy="administracao"
      menuList={formattedPermissions}
      backgroundHeader="primary"
      navigate={navigate}
      listMenusOpen={listMenusOpen}
    />
  );
};
