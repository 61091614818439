import { AdminArea } from "../../../components/Body/AdminArea";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { ContainerAdmin, ContainerLoading } from "./styles";
import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { DrawerDefault, GeneralInput, Grid } from "@kentarepo/kcf-assets";
import { useEffect, useState } from "react";
import { SystemConfigurationService } from "../../../services/systemConfiguration";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { useToast } from "../../../hooks/toast";
import { useSelector } from "react-redux";
import Loading from "react-loading";
import { IntegrationService } from "../../../services/integrations";
import { LoadSpinner } from "../../../features/LoadSpinner";

export interface IServiceIntegrationImportationItem {
  hearingTypeId: number;
  hearingTypeName: string;
  active: boolean;
  attendantRoleList: Array<IDefaultObject>;
}

export interface IDefaultObject {
  ServiceIntegrationImportationId?: number;
  id: number;
  name: string;
  active: boolean;
  attendantRoleId: number;
  hearingTypeId: number;
}

export interface IServiceIntegrationImportationResponseAPI {
  customerId: number;
  hearingType: Array<IDefaultObject>;
  attendantRole: Array<IDefaultObject>;
  ServiceIntegrationImportation: Array<IServiceIntegrationImportationItem>;
}

export interface IIntegrationImportation {
  expecteddate: string;
  placeid: any;
}

export const ServiceIntegrationImportation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [filterList, setFilterList] = useState<any>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [
    loadingModalServiceIntegrationImportation,
    setLoadingModalServiceIntegrationImportation,
  ] = useState(false);
  const { addToast } = useToast();
  const { userCustomerProfile } = useSelector((store: any) => store.profile);

  const user = getValueFromlocalStorage("@Kenta:user");
  let customerId: any = null;

  useEffect(() => {
    getAllConfigurationsByCustomerId();
  }, []);

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const getAllConfigurationsByCustomerId = async () => {
    setLoading(true);
    const { data } = await SystemConfigurationService.getAllByCustomer(
      customerId
    );
    const list = data.filter(
      (item: any) => item.name === "ServiceIntegrationImportation"
    );
    setFilterList(list);

    const initialValues: { [key: string]: string } = {};
    list.forEach((item: any) => {
      const value = JSON.parse(item.value);
      value.parametros.forEach((param: any) => {
        initialValues[`${item.name}-${param.name}`] = param.name;
      });
    });
    setInputValues(initialValues);
    setLoading(false);
  };

  const onclose = () => {
    getAllConfigurationsByCustomerId();
    setOpenModal(false);
  };

  const checkInputsFilled = async () => {
    try {
      const allFilled = () => {
        for (const key in inputValues) {
          if (!inputValues[key]) {
            return false;
          }
        }
        return true;
      };

      if (allFilled()) {
        setLoadingModalServiceIntegrationImportation(true);
        const response = await importIntegration();
        
        if (response === undefined) {
          return;
        }
        else if (response?.status === 200) {
          addToast({
            type: "success",
            title: "Importação",
            description: response.data?.message,
          });
          setOpenModal(false);          
        } else {
          addToast({
            type: "error",
            title: "Importação",
            description: response?.data?.message,
          });
          return;
        }
      } else {
        addToast({
          type: "warning",
          title: "Alteração de configuração",
          description: "Por favor preencha os campos obrigatórios",
        });
      }
    } finally {
      setLoadingModalServiceIntegrationImportation(false);
    }
  };

  const importIntegration = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, firstValue] = Object.entries(inputValues)[0];
  
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    if (!regex.test(firstValue)) {
      addToast({
        type: "warning",
        title: "Alteração de configuração",
        description: "Não está no formato DD/MM/AAAA",
      });
      return;
    }
  
    const match = firstValue.match(regex);
  
    if (match) {
      const day = parseInt(match[1], 10);
      const month = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);  

      const today = new Date();      const currentYear = today.getFullYear();
  

      if (
        month < 1 || month > 12 || 
        day < 1 || day > 31 ||
        year < currentYear - 10 || year > currentYear + 10 ||
        new Date(year, month - 1, day).getDate() !== day
      ) {
        addToast({
          type: "warning",
          title: "Alteração de configuração",
          description: "A data informada não é válida.",
        });
        return;
      }  

      const transformedValue = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  
      const body = {
        expecteddate: transformedValue,
        placeid: userCustomerProfile.placeId,
      };  
  
      return await IntegrationService.import(body);
    }
  };
  

  return (
    <ContainerAdmin>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        loading={loading}
        title={"Dados da importação"}
        InputSearchLabel="Buscar por:"
        list={filterList}
        headerTrashButtonOnClick={() => {}}
        onClick={() => setOpenModal(true)}
        setItemReturnClick={[]}
        setOpenModal={openModal}
        permissionName={FORMS_ADMIN.SERVICE_INTEGRATION_IMPORTATION}
        onChangeSituation={() => null}
        searchText={""}
        showStatus={false}
        displayAtivoFilter={false}
        description={true}
        showSearch={false}
      />
      <DrawerDefault
        openDrawer={openModal}
        setOpenDrawer={() => null}
        onClose={() => onclose()}
        title={"Dados da importação"}
        onApply={() => checkInputsFilled()}
        children={
          <>
            {loading ? (
              <ContainerLoading>
                <Loading width={40} type="spin" color="#000" />
              </ContainerLoading>
            ) : (
              <Grid container spacing={3}>
                {filterList?.map((item: any, index: any) => {
                  const value = JSON.parse(item.value);
                  return (
                    <Grid item xs={12} key={index}>
                      {value.parametros.map((param: any, paramIndex: any) => {
                        const inputId = `${item?.name}-${param?.name}`;
                        if (param.label === "Código da Vara") {
                          inputValues[inputId] = userCustomerProfile.placeId;
                        }
                        if (param.label === "Código do Cliente") {
                          inputValues[inputId] = customerId;
                        }
                        return (
                          <Grid item xs={12} key={paramIndex}>
                            <GeneralInput
                              id={inputId}
                              error={false}
                              value={inputValues[inputId] || ""}
                              required
                              onChange={(e) =>
                                setInputValues((prevValues) => ({
                                  ...prevValues,
                                  [inputId]: e,
                                }))
                              }
                              name={param?.name}
                              label={param.label}
                              disabled={
                                param.label === "Código do Cliente" ||
                                param.label === "Código da Vara"
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        }
      />
      <LoadSpinner
        loading={loadingModalServiceIntegrationImportation}
        zindex={2000}
      />
    </ContainerAdmin>
  );
};
