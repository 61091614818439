/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import { IIntegrationImportation } from "../../pages/app/ServiceIntegrationImportation";
import api from "../api";

export const IntegrationService = {
  import: async (data: IIntegrationImportation) => {
    if (data) {
      try {
        const response = await api.post<any>(`/Integration/Import`, data);
        return response;
      } catch (error) {
        const errorReturn = error as AxiosError;

        const errorApi = {
          status: errorReturn.status,
          data: errorReturn.response?.data,
        };

        return errorApi;
      }
    }  
  },
  notify: async (hearingId: number) => {
    try {
      const body = {
        hearingId: hearingId
      }
      const response = await api.post<any>(`/Integration/Notify`, body);
      return response;
    } catch (error) {
      const errorReturn = error as AxiosError;
      const errorApi = {
        status: errorReturn.status,
        data: errorReturn.response?.data,
      };

      return errorApi;
    } 
  }
};
