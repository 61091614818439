import styled from "styled-components";

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 700px;
  padding: 10px;

  @media (max-height: 768px) {
    max-height: 500px;
  }
`;

export default ScrollableContainer;
