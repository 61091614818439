/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import {
  BodyContainer,
  ButtonFinish,
  Container,
  ContainerButtons,
  ContainerDescription,
  FooterContainer,
  HeaderContainer,
  Subtitle,
  Title,
} from "./style";
// import BackArrow from "../../../assets/svg/bx-arrow-back.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import RecordButton from "../../../assets/svg/record-button.svg";
import RecordButtonDisabled from "../../../assets/svg/record-button-disabled.svg";
import V from "../../../assets/svg/v.svg";
import Share from "../../../assets/svg/share.svg";
import { useToast } from "../../../hooks/toast";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../../../utils/sessionStorageEncrypt";
import { useDispatch } from "react-redux";
import { setScreenStatus } from "../../../store/modules/screen_controll/actions";
import { HearingService } from "../../../services/hearing";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { ModalDefault, SearchEmpty } from "@kentarepo/kcf-assets";
import { onLoadList } from "../../../features/ImportCertificate/constants";
import api from "../../../services/api";
import { userUtils } from "../../../utils/user";
import { useQuery } from "../../../utils/get";


export default function HearingRecord() {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const [loadingStopRecording, setLoadingStopRecording] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [loadingStartRecording, setLoadingStartRecording] = useState(true);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [showModalConfirmationSair, setShowModalConfirmationSair] =
    useState(false);
  const [exibirSaindoComponent, setExibirSaindoComponent] = useState(false);
  const [recordStarted, setRecordStarted] = useState(false);
  const timeoutsRef = useRef<any[]>([]);
  const dispatch = useDispatch();
  const query = useQuery()

  const { create } = usePermissions(FORMS_HEARING.HEARING);
  const customerId = userUtils.getCustomerIdByUser();

  function setTimeToEndRecord(tempo: number) {
    insertTolocalStorage("timeToCount", String(tempo), "encrypt-time");
  }

  async function getTimeOfRecordForUser(customerId: number) {
    const response = await onLoadList(customerId, api);
    if (response.data && response.data.certificateType === 2) {
      setTimeToEndRecord(response.data.limitRecord);
    }
  }

  useEffect(() => {
    getTimeOfRecordForUser(customerId as number);
  }, []);

  const checkRecordingStatus = async () => {
    try {
      const response = await HearingService.getUserInRoom(
        location.state?.videoRoomId
      );
      if (response.body.ok) {
        setRecordStarted(true);
        addToast({
          type: "warning",
          title: "Gravação em andamento",
          description: "Você retornou a uma gravação já em andamento.",
        });
      } else {
        setTimeout(() => {
          setLoadingStartRecording(false);
        }, 5000);
        if (create) {
          addToast({
            type: "warning",
            title: "Aviso",
            description:
              "Lembre-se de clicar em gravar quando todos os participantes estiverem na sala.",
          });
        }
        setRecordStarted(false);
        localStorage.removeItem("isRecordStarted");
      }
    } catch (error) {
      console.error("Erro ao verificar status da gravação:", error);
      addToast({
        type: "error",
        title: "Erro ao verificar gravação",
        description:
          "Não foi possível verificar o status da gravação no servidor.",
      });
    } finally {
      setLoadingStartRecording(false);
    }
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/audiencias");
      return;
    }
    checkRecordingStatus();
    dispatch(setScreenStatus(true));
    return () => {
      timeoutsRef.current.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, []);

  const goBack = () => {
    dispatch(setScreenStatus(false));
  };

  const copyFunction = async () => {
    const copyText: any = document.getElementById("myInput");

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      await navigator.clipboard.writeText(copyText.value);
      addToast({
        type: "success",
        title: "Link copiado",
        description: "O link está salvo no seu ctrl+v",
      });
    }
  };

  const startVideoRecord = async () => {
    const existsCamPermission = await requestPermission();
    if (!existsCamPermission) {
      addToast({
        type: "error",
        title: "Permitir câmera",
        description:
          "É necessário que seja permitido o uso da câmera para prosseguir",
      });
      return;
    }

    if (location.state) {
      setDisabledButtons(true)
      setLoadingStartRecording(true);
      localStorage.setItem("isRecordStarted", "true");
      const { status } = await HearingService.startVideoRecording(
        location.state?.videoRoomId,
        location.state.authToken,
        location.state.deviceToken
      );

      if (status) {
        const time = getValueFromlocalStorage("timeToCount", "encrypt-time");
        if (!time) {
          addToast({
            type: "success",
            title: "Gravação iniciada",
            description: "Gravação iniciada",
          });
        }

        setLoadingStartRecording(false);
        setRecordStarted(true);
        verificarEChamarlimiteDeTempo();
      } else {
        addToast({
          type: "error",
          title: "Erro inesperado",
          description: "Nao foi possivel iniciar a gravação",
        });
        setLoadingStartRecording(false);
        setRecordStarted(false);
        setDisabledButtons(false)
      }
    }
  };

  const stopVideoRecord = async () => {
    if (location.state) {
      setLoadingStopRecording(true);
      const { status } = await HearingService.stopVideoRecording(
        location.state?.videoRoomId,
        location.state.authToken,
        location.state.deviceToken
      );

      if (status) {
        setExibirSaindoComponent(true);

        const response = await HearingService.deleteRoom(
          location.state?.videoRoomId,
          location.state.authToken,
          location.state.deviceToken
        );

        if (response.status) {
          setTimeout(() => {
            addToast({
              type: "success",
              title: "Vídeo finalizado",
              description: "o vídeo foi finalizado, aguarde a gravação",
            });
            setLoadingStopRecording(false);
          }, 3000);
          await HearingService.logoutUna();
          navigate(`/audiences?hearingId=${query.get("hearingId")}&hearingFileId=${query.get("hearingFileId")}`, {
            state: {
              procedureNumber: location.state?.procedureNumber,
            },
          });
        } else {
          addToast({
            type: "error",
            title: "Não foi possivel finalizar o vídeo",
            description: "O vídeo não foi finalizado como deveria.",
          });
          setExibirSaindoComponent(true);
          setLoadingStopRecording(false);
        }
      } else {
        addToast({
          type: "error",
          title: "Não foi possivel finalizar o video",
          description: "O video não foi finalizado como deveria.",
        });
        setExibirSaindoComponent(true);
        setLoadingStopRecording(false);
      }
    }
  };

  function verificarEChamarlimiteDeTempo() {
    const time = getValueFromlocalStorage("timeToCount", "encrypt-time");
    //Tempo ilimitado caso seja zero, solicitação Alexandre em 17/12/2024
    if (time && Number(time) > 0) {
      addToast({
        type: "warning",
        title: "Versão de demonstração",
        description: `tempo limite de gravação de ${time} minutos.`,
        timeLimitToast: 15000,
      });
      setLoadingStopRecording(false);

      const milissegundos = Number(time) * 60000;

      const timeoutFinish = setTimeout(() => {
        stopVideoRecord();
        setShowModalConfirmation(false);
      }, milissegundos);

      const timeout30SecondsLeft = setTimeout(() => {
        addToast({
          type: "warning",
          title: "Aviso",
          description: `Você só tem mais 30 segundos até finalizar`,
          timeLimitToast: 15000,
        });
      }, milissegundos - 30000);

      timeoutsRef.current = [timeoutFinish, timeout30SecondsLeft];
    }
  }

  async function onClickBackButton() {
    try {
      setShowModalConfirmationSair(false);
      setExibirSaindoComponent(false);

      // finalizar a gravação antes de sair
      const { status } = await HearingService.deleteRoom(
        location.state?.videoRoomId,
        location.state.authToken,
        location.state.deviceToken
      );

      if (!status) {
        return addToast({
          type: "warning",
          title: "Aviso",
          description:
            "Não foi possivel finalizar a sala de gravação",
        })
      }

      setShowModalConfirmation(false);
      navigate(-1);
      goBack();
    } catch (error) {
      return addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Não foi possivel finalizar a sala de gravação",
      })
    }
  }

  async function requestPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      stream.getTracks().forEach((track) => track.stop()); // Stop the tracks
      return true; // Permission granted
    } catch (error) {
      return false; // Permission denied or other error
    }
  }

  const handleIframeLoad = () => {
    setTimeout(() => {
      const unaIframe: any = document.getElementById("unaIframe");

      if (unaIframe && unaIframe.contentWindow && unaIframe.contentWindow.document) {
        const existsCallContainer = unaIframe.contentWindow.document.getElementsByClassName("video-call-container");
        const existsCall = existsCallContainer.length > 0 ? existsCallContainer[0] : null;
        const errorContentContainer = unaIframe.contentWindow.document.getElementsByClassName("error-content__text");
        const errorContent = errorContentContainer.length > 0 ? errorContentContainer[0] : null;
        if (existsCall && !errorContent) {
          setDisabledButtons(false);
        }

        const footerBar = unaIframe.contentWindow.document.getElementsByClassName("video-call-footer-bar");
        if (footerBar.length > 0) {
          const buttonsContainer = footerBar[0].getElementsByClassName("video-call-footer-bar__buttons");
          if (buttonsContainer.length > 0) {
            const contentDiv = buttonsContainer[0].getElementsByTagName("div");
            if (contentDiv.length > 0) contentDiv[0].style.display = "none";
            if (contentDiv.length > 1) contentDiv[1].style.display = "none";
            if (contentDiv.length > 2) contentDiv[2].style.display = "none";
          }
        }
        const botaoSair = unaIframe.contentWindow.document.querySelector('div[id^="ember923"]');
        if (botaoSair) {
          botaoSair.style.display = "none";
        }
      }
    }, 10000);
  };

  const getUnaIframeBaseURL = () =>
    import.meta.env.DEV &&
    window.location.hostname.includes("trycloudflare")
      ? import.meta.env.VITE_APP_NGROKUNABASEURL
      : import.meta.env.VITE_APP_BASEURL_SITE;

  return (
    <Container>
      <HeaderContainer>
        <ContainerDescription>
          <Title
            style={{
              paddingLeft: 20,
            }}
          >
            Audiência {location.state?.procedureNumber}
          </Title>
          <Subtitle>{location.state?.procedureCustomerSourceName}</Subtitle>
        </ContainerDescription>
      </HeaderContainer>
      {create ? (
        <>
          <BodyContainer>
            {exibirSaindoComponent ? (
              SaindoComponent()
            ) : (
              <iframe
                id="unaIframe"
                onLoad={handleIframeLoad}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={`${getUnaIframeBaseURL()
                  }/una/onlyVideoRoom?roomId=${location.state?.urlModerator.split("=")[1]
                  }&inviteUsers=false`}
                title="W3Schools Free Online Web Tutorials"
              ></iframe>
            )}
          </BodyContainer>
          <FooterContainer>
            <ContainerButtons>
              <input
                type="text"
                value={location.state?.urlAttendee}
                id="myInput"
                style={{ display: "none" }}
              ></input>

              {loadingStartRecording ? (
                <ReactLoading
                  type="spin"
                  color="#F2A60D"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src={
                    recordStarted || disabledButtons
                      ? RecordButtonDisabled
                      : RecordButton
                  }
                  alt=""
                  onClick={() => {
                    if (!recordStarted || !disabledButtons) startVideoRecord();
                  }}
                  style={{
                    cursor:
                      recordStarted || disabledButtons
                        ? "not-allowed"
                        : "pointer",
                  }}
                />
              )}

              <ButtonFinish color="primary" onClick={copyFunction}>
                <img
                  src={Share}
                  alt=""
                  width={24}
                  height={24}
                  style={{ marginRight: 4 }}
                />
                Compartilhar link
              </ButtonFinish>
              {loadingStopRecording ? (
                <ReactLoading
                  type="spin"
                  color="#a50303"
                  width={24}
                  height={24}
                />
              ) : (
                <ButtonFinish
                  id="finish-record"
                  color="forth"
                  disabled={
                    !recordStarted ||
                    !(localStorage.getItem("isRecordStarted") === "true")
                  }
                  onClick={() => {
                    if (recordStarted) setShowModalConfirmation(true);
                  }}
                >
                  <img src={V} alt="" style={{ marginRight: 4 }} />
                  Finalizar gravação
                </ButtonFinish>
              )}
            </ContainerButtons>
          </FooterContainer>
        </>
      ) : (
        <div style={{ height: "100%", marginTop: "30px" }}>
          <BodyContainer>
            <SearchEmpty
              typeIcon="avatar"
              textMessage="Sem permissão para seu perfil"
            />
          </BodyContainer>
        </div>
      )}

      <ModalDefault
        openModal={showModalConfirmation}
        setOpenModal={setShowModalConfirmation}
        title={"Finalizar gravação"}
        bodyMessageTitle={"Deseja realmente finalizar a gravação?"}
        bodyMessageSubTitle={"Esta ação não poderá ser desfeita"}
        textConfirm={"FINALIZAR"}
        onClickConfirm={() => {
          stopVideoRecord();
          setShowModalConfirmation(false);
          goBack();
        }}
        textCancel={"CANCELAR"}
        bodyMessageMarginBetweenTitleAndSubTitle="30px"
        bodyMessageMarginTop="30px"
        onClickCancel={() => {
          setShowModalConfirmation(false);
        }}
      />

      <ModalDefault
        openModal={showModalConfirmationSair}
        setOpenModal={setShowModalConfirmationSair}
        title={"Sair da sala"}
        bodyMessageTitle={"Deseja realmente sair da sala de gravação?"}
        bodyMessageSubTitle={
          !recordStarted
            ? "Para sair da gravação iniciada, clique em finalizar a gravação"
            : "Esta ação não poderá ser desfeita"
        }
        textConfirm={"SAIR"}
        onClickConfirm={onClickBackButton}
        textCancel={"CANCELAR"}
        bodyMessageMarginBetweenTitleAndSubTitle="30px"
        bodyMessageMarginTop="30px"
        onClickCancel={() => {
          setShowModalConfirmationSair(false);
        }}
      />
    </Container>
  );
}

function SaindoComponent() {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title
        style={{
          fontSize: 40,
          color: "black",
        }}
      >
        Saindo da sala...
      </Title>
    </Container>
  );
}
