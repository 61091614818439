import styled from "styled-components";
import { globalStyle, colors } from "@kentarepo/kcf-assets";

export const Container = styled.div`
  height: 72.6vh;
`;

export const ContentTranscription = styled.div`
  height: 95%;
  width: 100%;
  overflow-y: auto;
`;

export const HighlightedWord = styled.span`
  font-size: 14px;
  font-family: ${globalStyle.fontFamily};
  font-weight: bold;
  color: ${colors.default_base_error};
  background-color: rgb(200, 255, 0);
  cursor: pointer;
`;

export const NormalWord = styled.span`
  font-size: 14px;
  font-family: ${globalStyle.fontFamily};
  color: #000;
  cursor: pointer;
`;

export const ContainerTiny = styled.div`
  height: 95%;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 1366px) and (max-height: 768px) {
    height: 86%;
  }
`;
