import { DivEmail, DivLogo, LinkContainer } from "../styles";
import { Grid } from "@mui/material";
import React from "react";
import { TextForgotPasswordInfo } from "./styles";
import {
  BaseLabel,
  Button,
  GeneralInput,
  TextLink,
  colors,
} from "@kentarepo/kcf-assets";

export const validEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


export const ForgotPasswordStepOne = (
  email: string,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (e: any) => Promise<void>,
  error: boolean,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate?: any
) => {
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>     
        <Grid alignContent="center">
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "310px",
            }}
          >
            <DivLogo>
              <BaseLabel
                fontSize="18px"
                fontWeight="700"
                children={"Recuperação de senha"}
              />
              <BaseLabel
                fontSize="14px"
                fontWeight="500"
                marginTop="13px"
                children={
                  "Informe o e-mail que você utiliza para acessar sua conta."
                }
              />
            </DivLogo>
            <DivEmail marginTop="27px">
              <GeneralInput
                label={"Seu e-mail"}
                name={"email"}
                onChange={setEmail}
                value={email}
                error={error}
                setError={setError}
              />
            </DivEmail>
            <Button id="sign-in_button" marginBottom="16px" type="submit" onClick={(e: any) => handleSubmit(e)}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </form>
      <LinkContainer type="submit" onClick={() => navigate("/")}>
        <TextLink>Voltar ao login</TextLink>
      </LinkContainer>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ForgotPasswordStepTwo = (navigate: any, navigateTo: string) => {
  return (
    <>
      <form>
        <Grid alignContent="center">
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width:"390px",
              marginTop: "315px",
            }}
          >
            <TextForgotPasswordInfo>
              <BaseLabel
                fontSize="18px"
                fontWeight="700"
                children={"Verifique seu e-mail"}
                marginBottom="0px"
                marginTop="0px"
                marginLeft="0px"
                marginRight="0px"
              />
            </TextForgotPasswordInfo>
            <TextForgotPasswordInfo paddingTop="29px">
              <BaseLabel
                fontSize="14px"
                fontWeight="500"
                marginBottom="0px"
                marginTop="0px"
                marginLeft="0px"
                marginRight="0px"
                children={
                  "Enviamos para você as instruções para a criação de uma nova senha."
                }
              />
            </TextForgotPasswordInfo>
            <TextForgotPasswordInfo paddingTop="28px">
              <BaseLabel
                fontSize="14px"
                fontWeight="500"
                marginBottom="0px"
                marginTop="0px"
                marginLeft="0px"
                marginRight="0px"
                children={
                  "Caso não tenha recebido a mensagem, repita o procedimento."
                }
              />
            </TextForgotPasswordInfo>
            <TextForgotPasswordInfo paddingTop="27px">
              <BaseLabel
                fontSize="14px"
                fontWeight="500"
                marginBottom="0px"
                marginTop="0px"
                marginLeft="0px"
                marginRight="0px"
                children={
                  "Se o problema persistir, entre contato com nosso suporte técnico."
                }
              />
            </TextForgotPasswordInfo>
            <TextForgotPasswordInfo paddingTop="24px">
              <Button
                id="sign-in_button"
                type="submit"
                marginBottom="16px"
                backgroundColor="white"
                borderColor="#1D9BF0"
                textColor="#1D9BF0"
                backgroundHoverColor="#0D77BF"
                borderHoverColor="#1D9BF0"
                textColorHover={colors.neutral_white}
                onClick={() => navigate(navigateTo)}
              >
                Voltar ao login
              </Button>
            </TextForgotPasswordInfo>
          </Grid>
        </Grid>
      </form>      
    </>
  );
};
