import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { IChangeLog } from "./interface";

export const changeLogService = {
  getAll: async () => {
    try {
      const { data } = await api.get<Array<IChangeLog>>("/_changeLog/Get");

      return {
        status: true,
        data: data,
        message: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message:
            error?.response?.data.message ||
            error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
    }
  },
};
