import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import { DropdownGlobal } from '../../components/DropdownGlobal';
import VideoCamera from '../../components/VideoCamera';
import { videoConstraints } from '../../components/VideoCamera/constants';
import { ContainerMic } from '../../components/MicLevelDisplay/styles';
import { MdMic } from 'react-icons/md';
import { DrawerDefault } from '@kentarepo/kcf-assets';
import { customerPreferencesService } from '../../services/customerPreferences';
import { LogOperation } from '../../utils/entities/logOperation';
import { useToast } from '../../hooks/toast';
import { getValueFromlocalStorage, insertTolocalStorage } from '../../utils/sessionStorageEncrypt';
import { ICustomerPreferences } from '../../services/customerPreferences/interface';
import { useDispatch } from 'react-redux';
import { changeUserPreferences } from '../../store/modules/user/actions';
import { Tooltip } from 'react-tooltip';

const SelectAudioCamera: React.FC<{ open: boolean, setOpen: (open: boolean) => any; preferences: ICustomerPreferences | null }> = ({ open, setOpen, preferences }) => {
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[] | any[]>([]);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[] | any[]>([]);
  const [micDevices, setMicDevices] = useState<MediaDeviceInfo[] | any[]>([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<any>({ deviceId: "", label: "" });
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<any>({ deviceId: "", label: "" });
  const [selectedMicDevice, setSelectedMicDevice] = useState<any>({ deviceId: "", label: "" });
  const [videoConstants, setVideoContants] = useState<any>(videoConstraints)
  const [audioElement, setAudioElement] = useState<any | null>(null);
  const dispatch = useDispatch()
  const [userPermission, setUserPermission] = useState(false);


useEffect(() => {
  (async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      setUserPermission(true);
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.warn("Permissão de câmera ou microfone negada ou indisponível");
      setUserPermission(false);
    }
  })();
}, []);

  const toast = useToast()

  const [micLevel, setMicLevel] = useState(0);
  const [micStream, setMicStream] = useState<MediaStream | null>(null)

  const checkMicAvailability = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMicrophone = devices.some(device => device.kind === "audioinput");

      if (hasMicrophone) {
        console.log("Microfone disponíveis ✅");
        return true;
      } else {
        console.warn("Nenhum Microfone encontrado ❌");
        return false;
      }
    } catch (error) {
      console.log("Erro ao verificar o Microfone:", error);
      return false;
    }
  };
  const checkCameraAvailability = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some(device => device.kind === "videoinput");

      if (hasCamera) {
        await navigator.mediaDevices.getUserMedia({ video: true });
        console.log("Camera disponíveis ✅");
        return true;
      } else {
        console.warn("Nenhum Camera encontrado ❌");
        return false;
      }
    } catch (error) {
      console.log("Erro ao verificar o Camera:", error);
      return false;
    }
  };
  const checkAudioAvailability = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudio = devices.some(device => device.kind === "audiooutput");

      if (hasAudio) {
        console.log("Audio disponíveis ✅");
        return true;
      } else {
        console.warn("Nenhum Audio encontrado ❌");
        return false;
      }
    } catch (error) {
      console.log("Erro ao verificar o Audio:", error);
      return false;
    }
  };

  async function requestMicrophonePermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone access granted", stream);
    } catch (error) {
      console.error("Microphone access denied", error);
    }
  }

  async function checkMicrophonePermission() {
    const permissionStatus = await navigator.permissions.query({ name: "microphone" as PermissionName });

    console.log("Microphone permission status:", permissionStatus.state);

    if (permissionStatus.state === "granted") {
      console.log("Microphone permission already granted");
      return true
    } else if (permissionStatus.state === "denied") {
      console.log("Microphone permission denied");
      return false
    } else {
      console.log("Microphone permission needs to be requested");
      await requestMicrophonePermission()
      return false
    }
  }

  useEffect(() => {
    (async () => {
      await checkMicrophonePermission()
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const hasMicPermission = await checkMicAvailability()
      const hasMicPermissionGranted = await checkMicrophonePermission()
      if (hasMicPermissionGranted && hasMicPermission) {
        if (open) {
          startMic()
        } else {
          if (micStream) {
            stopMicrophone()
          }
        }
      }
    })()
  }, [selectedMicDevice, open])

  //  Audio get devices
  useEffect(() => {
    (async () => {
      const hasAudioPermission = await checkAudioAvailability()
      if (!selectedAudioDevice.deviceId) {
        getDevices();
      }

      if (hasAudioPermission) {
        const audio = new Audio();
        setAudioElement(audio);

        return () => {
          setAudioElement(null);
        };
      }
    })()

  }, [selectedAudioDevice.deviceId, open]);

  const startMic = async () => {
    try {
      stopMicrophone()

      const stream: any = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: selectedMicDevice.deviceId } } });
      setMicStream(stream)

      const audioContext = new (window.AudioContext)();
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser: any = audioContext.createAnalyser();

      microphone.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateMicLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((acc, value) => acc + value, 0);
        const average = sum / dataArray.length;
        setMicLevel(average);
        requestAnimationFrame(updateMicLevel);
      };

      updateMicLevel();
    } catch (error) {
      console.log("Error starting microphone:", error);
    }
  };

  const stopMicrophone = () => {
    if (micStream) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      }).catch((err) => console.log(err));

      setMicLevel(0)
      micStream.getTracks().forEach((track) => track.stop());
    }
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Filtra os dispositivos de vídeo e áudio
      const videoInputs = devices.filter((device) => device.kind === "videoinput");
      const audioOutput = devices.filter((device) => device.kind === "audiooutput");
      const micInputs = devices.filter((device) => device.kind === "audioinput");

      if (videoInputs.length > 0) {
        setVideoDevices(videoInputs.map(v => {
          return {
            id: v.deviceId,
            label: v.label,
            name: v.label,
            selected: false
          }
        }))
      }

      if (audioOutput.length > 0) {
        setAudioDevices(audioOutput.map(a => {
          return {
            id: a.deviceId,
            label: a.label,
            name: a.label,
            selected: false
          }
        }))
      }

      if (micInputs.length > 0) {
        setMicDevices(micInputs.map(m => {
          return {
            id: m.deviceId,
            label: m.label,
            name: m.label,
            selected: false
          }
        }))
      }

      if (preferences) {
        const videoPreference = preferences.videoPreference ? JSON.parse(preferences.videoPreference) : null
        const micPreference = preferences.micPreference ? JSON.parse(preferences.micPreference) : null
        const audioPreference = preferences.audioPreference ? JSON.parse(preferences.audioPreference) : null

        if (audioPreference) changeAudio(audioPreference.deviceId, audioPreference.label)
        if (micPreference) changeMic(micPreference.deviceId, micPreference.label)
        if (videoPreference) changeCamera(videoPreference.deviceId, videoPreference.label)

        return
      }

      // Define os dispositivos padrão (primeiro da lista)
      if (videoInputs.length > 0) changeCamera(videoInputs[0].deviceId, videoInputs[0].label);
      if (audioOutput.length > 0) {
        setSelectedAudioDevice({ deviceId: audioOutput[0].deviceId, label: audioOutput[0].label });
      }
      if (micInputs.length > 0) setSelectedMicDevice({ deviceId: micInputs[0].deviceId, label: micInputs[0].label });
    } catch (error) {
      console.log("Erro ao obter dispositivos de mídia:", error);
    }
  };

  const changeCamera = async (deviceId: string, label?: string) => {
    if (!deviceId) return

    const cameraPermission = await checkCameraAvailability()
    if (!cameraPermission) return

    setVideoContants({
      ...videoConstraints,
      deviceId: { exact: deviceId },
      label
    })
    setSelectedVideoDevice({ deviceId, label })
  }
  const changeMic = async (deviceId: string, label?: string) => {
    if (!deviceId) return

    const micPermission = await checkMicAvailability()
    if (!micPermission) return

    if (micStream) {
      micStream.getTracks().forEach((track) => track.stop());
    }

    const constraints = deviceId
      ? { audio: { deviceId: { exact: deviceId } } }
      : { audio: true };

    const newStream = await navigator.mediaDevices.getUserMedia(constraints);

    setMicStream(newStream)

    if (selectedMicDevice) {
      setSelectedMicDevice(null)

    }

    setSelectedMicDevice({ deviceId, label })
  }

  const changeAudio = async (deviceId: string, label?: string) => {
    if (!deviceId) return
    const audioPermission = await checkAudioAvailability()
    if (!audioPermission) return

    if (deviceId && label) {
      setSelectedAudioDevice({ ...selectedAudioDevice, deviceId, label })
      if (audioElement && 'setSinkId' in audioElement) {
        try {
          await audioElement.setSinkId(deviceId); // Altera o sinkId
        } catch (error) {
          console.log("Erro ao alterar o dispositivo de saída de áudio:", error);
        }
      }
    }


  }

  const onApplyDevices = async () => {
    const audioPreference = JSON.stringify(selectedAudioDevice)
    const videoPreference = JSON.stringify(selectedVideoDevice)
    const micPreference = JSON.stringify(selectedMicDevice)

    const userId = LogOperation.getUserId()
    const machineIp = LogOperation.getMachineIP()


    if (preferences?.id) {
      const { success } = await customerPreferencesService.put({
        id: preferences?.id,
        audioPreference,
        videoPreference,
        micPreference,
        deviceRegistration: machineIp,
        userId: userId
      })

      if (success) {
        toast.addToast({
          type: "success",
          title: "Configuração de dispositivos",
          description: "Configuração de dispositivos atualizada com sucesso",
        })

        const user = customerPreferencesService.GetByUserId(userId)
        insertTolocalStorage("@Kenta:user", JSON.stringify(user));
        setOpen(false)
      }

      if (micStream) {
        setMicLevel(0)
        micStream.getTracks().forEach((track) => track.stop());
      }

      return setOpen(false)
    }



    const { success } = await customerPreferencesService.add({
      audioPreference,
      videoPreference,
      micPreference,
      deviceRegistration: machineIp,
      userId: userId
    })

    if (success) {
      toast.addToast({
        type: "success",
        title: "Configuração de dispositivos",
        description: "Configuração de dispositivos salva com sucesso",
      })

      const { body } = await customerPreferencesService.GetByUserId(userId);

      dispatch(changeUserPreferences(body))
      const userStorage = getValueFromlocalStorage("@Kenta:user")
      if (userStorage) {
        const userParsed = JSON.parse(userStorage)
        insertTolocalStorage("@Kenta:user", JSON.stringify({ ...userParsed, preferences: body }));
      }

      insertTolocalStorage("@Kenta:user_preferences", JSON.stringify(body));
      setOpen(false)
    }

    setOpen(false)
  }

  return (
    <DrawerDefault onClose={() => setOpen(!open)} setOpenDrawer={() => { }} title='Configuração de dispositivos' onApply={userPermission ? onApplyDevices : undefined} openDrawer={open}   disabled={!userPermission} >
      <Container>
      {!userPermission && <Tooltip id="mic-tooltip" place="top" />}
      <div
        data-tooltip-id={!userPermission ? "mic-tooltip" : undefined}
        data-tooltip-content="Permissão de acesso ao microfone necessária"
      >
        <DropdownGlobal wantAnObject width='380px' defaultValue={userPermission ? selectedMicDevice?.label : ""}  showDefaultValue={userPermission && Boolean(selectedMicDevice?.label)} options={micDevices as any} labelName='Microfone' setSelected={(e: any) => changeMic(e.id, e.label)}  disabled={!userPermission || micDevices.length === 0} />
        <div>
          <div>
            <div className="container">
              {Array.from({ length: 30 }).map((_, i) => (
                <div
                  className="bar"
                  key={i}
                  style={{
                    backgroundColor:
                      Math.floor(micLevel % 30) > i ? "#A0A0A0" : undefined
                  }}
                />
              ))}
            </div>
            <ContainerMic>
              <MdMic color="blue" />
              <input
                style={{ width: 440 }}
                id="volume"
                min="0"
                max="100"
                value={Number(micLevel)}
                disabled
                type="range"
              />
            </ContainerMic>
          </div>
        </div>
        </div>
        {!userPermission && <Tooltip id="audio-tooltip" place="top" />}
      <div
        data-tooltip-id={!userPermission ? "audio-tooltip" : undefined}
        data-tooltip-content="Permissão de acesso ao áudio necessária"
      >
        <DropdownGlobal wantAnObject width='380px' defaultValue={userPermission ? selectedAudioDevice?.label : ""}  showDefaultValue={userPermission && Boolean(selectedAudioDevice?.label)} options={audioDevices as any} labelName='Áudio' setSelected={(e: any) => changeAudio(e.id, e.label)}  disabled={!userPermission || audioDevices.length === 0} />
        </div>
        {!userPermission && <Tooltip id="video-tooltip" place="top" />}
      <div
        data-tooltip-id={!userPermission ? "video-tooltip" : undefined}
        data-tooltip-content="Permissão de acesso à câmera necessária"
      >
        <DropdownGlobal wantAnObject width='380px' defaultValue={userPermission ? selectedVideoDevice?.label : ""} showDefaultValue={userPermission && Boolean(selectedVideoDevice?.label)} options={videoDevices as any} labelName='Vídeo' setSelected={(e: any) => changeCamera(e.id, e.label)}   disabled={!userPermission || videoDevices.length === 0}/>
        </div>
        <VideoCamera videoConstraints={videoConstants} />
      </Container>
    </DrawerDefault>
  )
}

export default SelectAudioCamera;