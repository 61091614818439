import { BaseLabel, colors, SearchEmpty } from "@kentarepo/kcf-assets";
import { changeLogService } from "../../../services/changeLog";
import ScrollableContainer from "./styles";
import {
  ChangeLogData,
  GroupedVersion,
  ReleaseNote,
  Version,
} from "./interfaces";

export const groupByVersionNumber = (versions: Version[]): GroupedVersion[] => {
  return versions.reduce((acc: GroupedVersion[], current: Version) => {
    const existingVersion = acc.find(
      (v) => v.VersionNumber === current.versionNumber
    );

    if (existingVersion) {
      existingVersion.ReleaseNotes.push(...current.releaseNotes);
    } else {
      acc.push({
        VersionNumber: current.versionNumber,
        Description: current.description,
        ReleaseDate: current.releaseDate,
        ReleaseNotes: [...current.releaseNotes],
      });
    }

    return acc;
  }, []);
};

export const groupVersions = (data: ChangeLogData) => {
  const { productVersionsBase, productVersionsCustomizations } = data.data;

  const groupedBase = productVersionsBase.map((version) => ({
    versionNumber: version.versionNumber,
    description: version.description,
    releaseDate: version.releaseDate,
    releaseNotes: version.releaseNotes,
  }));

  const groupedCustomizations = productVersionsCustomizations.map(
    (version) => ({
      versionNumber: version.versionNumber,
      description: version.description,
      releaseDate: version.releaseDate,
      releaseNotes: version.releaseNotes,
    })
  );

  const returnChangeLogData: ChangeLogData = {
    data: {
      productVersionsBase: groupedBase,
      productVersionsCustomizations: groupedCustomizations,
    },
  };
  return returnChangeLogData;
};

export const getAllChangeLogs = async () => {
  const response = await changeLogService.getAll();
  return response?.data;
};

export const handleChangeLog = (
  changeLogData: ChangeLogData,
  productVersionsBase: Version[],
  productVersionsCustomizations: Version[]
) => {
  const isChangeLogValid = changeLogData !== undefined && changeLogData !== null;
  const isBaseValid = productVersionsBase && productVersionsBase.length > 0;
  const isCustomValid = productVersionsCustomizations && productVersionsCustomizations.length > 0;
  
  if (!isChangeLogValid && !isBaseValid && !isCustomValid) {
    return <SearchEmpty textMessage={"Nenhuma versão disponível"} typeIcon={"file"} />;
  }
  
  return (
    <ScrollableContainer>
      {isBaseValid ? (
        <div>
          <h3 style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "10px" }}>
            Versões Base
          </h3>
          {productVersionsBase.map((version: Version) => (
            <div
              style={{
                marginBottom: "10px",
                border: `1px solid ${colors.neutral_gray_90}`,
                borderRadius: "8px",
                backgroundColor: colors.neutral_gray_90,
                padding: "10px",
              }}
              key={version.versionNumber}
            >
              <BaseLabel children={version.versionNumber} fontWeight="700" fontSize="20px" />
              <BaseLabel
                children={new Date(version.releaseDate).toLocaleDateString()}
                fontWeight="700"
                fontSize="14px"
              />
              <BaseLabel children={version.description} fontWeight="700" fontSize="14px" />
              <ul style={{ paddingLeft: "30px" }}>
                {version.releaseNotes.map((note: ReleaseNote, index: number) => (
                  <li key={index}>
                    <BaseLabel children={note.noteDescription} fontSize="14px" />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
  
      {isCustomValid ? (
        <div>
          <h3
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Versões Personalizadas
          </h3>
          {productVersionsCustomizations.map((version: Version) => (
            <div
              style={{
                marginBottom: "10px",
                border: `1px solid ${colors.neutral_gray_90}`,
                borderRadius: "8px",
                backgroundColor: colors.neutral_gray_90,
              }}
              key={version.versionNumber}
            >
              <BaseLabel children={version.versionNumber} fontWeight="700" fontSize="20px" />
              <BaseLabel
                children={new Date(version.releaseDate).toLocaleDateString()}
                fontWeight="700"
                fontSize="14px"
              />
              <BaseLabel children={version.description} fontWeight="700" fontSize="14px" />
              <ul style={{ paddingLeft: "30px" }}>
                {version.releaseNotes.map((note: ReleaseNote, index: number) => (
                  <li key={index}>
                    <BaseLabel children={note.noteDescription} fontSize="14px" />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
  
      {!isBaseValid && !isCustomValid ? (
        <SearchEmpty textMessage={"Nenhuma versão disponível"} typeIcon={"file"} />
      ) : null}
    </ScrollableContainer>
  );
  

};

export const handleChangeLogHtml = (
  changeLogData: ChangeLogData
): JSX.Element => {
  const { productVersionsBase, productVersionsCustomizations } =
    changeLogData.data;

  return handleChangeLog(
    changeLogData,
    productVersionsBase,
    productVersionsCustomizations
  );
};
