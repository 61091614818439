import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";

export const hearingTranscriptions = {

  GetAllByHearingId: async (hearingId:number) => {
    try {
      const { data } = await api.get<any>(
        `/HearingTranscription/GetAllByHearingId?hearingId=${hearingId}`
      );  

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }
    }
  },
  deleteHearingTranscriptionById: async (id: number): Promise<any> => {
    try {
      const { data } = await api.delete<any>(
        `/HearingTranscription/Delete?id=${id}`
      );  

      return {
        success: true,
        data: data,
        message: data.message,
      };
    } catch (error) {

      if (error instanceof AxiosError) {
        let { severity, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          success: false,
          message: message ? message : "Error",
          data,
        };
      }
    }
  }
};
