/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import api from "../../../services/api";
import { useDispatch } from "react-redux";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import { IUser } from "../../../utils/user";
import { changeUserPreferences, updateUser } from "../../../store/modules/user/actions";
import { deleteFromlocalStorage, insertTolocalStorage } from "../../../utils/sessionStorageEncrypt";
import { IProfile } from "./interface";
import { setPermissions } from "../../../store/modules/permission/actions";
import { menusListDefault } from "../../../utils/menus";
import { IMenuList } from "../../../routers/interface";
import { LeftTemplate } from "../../../features/auth/LeftTemplate";
import { Form } from "../../../features/forms/Form";

import {
  selectCustomerProfile,
  setMenuListAction,
  setProfileNameAction,
} from "../../../store/modules/profile/actions";

import {
  IGetAllPermissionByCustomerIdAndProfileId,
  permissionServices,
  profileServices,
} from "@kentarepo/kcf-services";
import { globalConfigurationsUtils } from "../../../services/utils/globalConfigurations";
import { CustomerCertificateService } from "../../../services/customerCertificate";
import { LogOperation } from "../../../utils/entities/logOperation";
import { clearCookies } from "../../../utils/cookies";

export const SignIn: React.FC = () => {
  useEffect(() => {
    (async () => {
      await LogOperation.setMachineIP();
      clearCookies()
      dispatch(changeUserPreferences(null))
      deleteFromlocalStorage("@Kenta:user_preferences")

    })();
  }, []);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleGetProfile({
    userCustomerId,
  }: {
    userCustomerId: number;
  }) {
    const apiProfileService = profileServices(api);

    const { data, success } =
      await apiProfileService.getUserCustomerProfileByPlace({
        customerId: userCustomerId,
      });

    if (success) {
      insertTolocalStorage("@Kenta:profiles", JSON.stringify(data));
      return data;
    }
  }

  async function recuperarTempoDeGravacaoPeloUsuario(customerId: number) {
    const response = await CustomerCertificateService.getCustomerCertificate(
      customerId
    );

    if (response && response.data && response.data.limitRecord) {
      setarTempoLimiteGravacao(response.data.limitRecord);
    }
  }

  function setarTempoLimiteGravacao(time: number) {
    insertTolocalStorage("timeToCount", String(time), "encrypt-time");
  }

  async function handleGetPermissions({
    customerId,
    profileId,
  }: IGetAllPermissionByCustomerIdAndProfileId) {
    const apiPermissionservice = permissionServices(api);

    const { data, success } =
      await apiPermissionservice.getAllPermissionByCustomerIdAndProfileId({
        customerId,
        profileId,
      });

    if (success) {
      insertTolocalStorage("@Kenta:permissions", JSON.stringify(data || []));
      dispatch(setPermissions(data || []));
      return data || [];
    }
  }

  const afterSignFunction = async ({
    user,
  }: {
    user: IUser;
  }): Promise<string> => {
    let profile: undefined | any;

    if (user) {
      dispatch(updateUser(user));

      const profileList = await handleGetProfile({
        userCustomerId: user.id,
      });

      await recuperarTempoDeGravacaoPeloUsuario(user.customerId)

      if (profileList && profileList.length) {
        profile = profileList.find(
          (props: IProfile) => props.profile === "Master"
        );

        const selectedProfile =
          profile && profile.active
            ? {
              ...profile,
              profileName: profile?.profile,
              placeId: profile?.places[0]?.id,
              title: profile?.places[0]?.name,
              profileId: profile?.profileId,
            }
            : {
              ...profileList[0],
              profileName: profileList[0]?.profile,
              placeId: profileList[0]?.places[0]?.id,
              title: profileList[0]?.places[0]?.name,
              profileId: profileList[0]?.profileId,
            };

        dispatch(selectCustomerProfile(selectedProfile));

        insertTolocalStorage("@Kenta:profile", JSON.stringify(selectedProfile));

        dispatch(
          setProfileNameAction(
            profile && profile.active ? "Master" : "Administrador"
          )
        );

        const data = await handleGetPermissions({
          customerId: user?.customerId,
          profileId:
            profile && profile.active
              ? profile.profileId
              : profileList[0].profileId,
        });

        const menuList: Array<string> = [];
        if (data && data.length) {
          data.forEach((element: any) => {
            if (
              !menuList.includes(
                element.menu
                  .normalize("NFD")
                  .replace(/[^a-zA-Z\s]/g, "")
                  .toLowerCase()
              )
            ) {
              menuList.push(
                element.menu
                  .normalize("NFD")
                  .replace(/[^a-zA-Z\s]/g, "")
                  .toLowerCase()
              );
            }
          });

          const filteredMenus: Array<IMenuList> = menusListDefault.filter(
            (x) =>
              x.rolesToShow.includes(
                profile && profile.active ? "Master" : "Administrador"
              ) &&
              menuList.includes(
                x.label
                  ?.normalize("NFD")
                  ?.replace(/[^a-zA-Z\s]/g, "")
                  ?.toLowerCase()
              )
          );

          dispatch(setMenuListAction(filteredMenus));

          insertTolocalStorage("@Kenta:menusList", JSON.stringify(menuList));
        }
      }

      //add shortcuts to localstorage
      await globalConfigurationsUtils.setLocalstorage(user.customerId);
    }
    return "/dashboard";
  };

  return (
    <Container>
      <LeftTemplate backgroundImage="default" />
      <Form
        addToast={addToast}
        variant="login"
        navigateToLogin={"/dashboard"}
        navigateToImportCertificate="/ImportCertificate"
        navigateToForgotPassword="/forgotPassword"
        navigate={navigate}
        afterSignFunction={afterSignFunction}
        api={api}
      />
    </Container>
  );
};
