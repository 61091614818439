import { useEffect, useState } from "react";
import { stringFormat } from "../../../utils/stringFormat";
import { CreateEditCustomerTemplate } from "./CreateEdit";

//Toast
import { useDispatch } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";

import { LogOperation } from "../../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { AdminArea } from "../../../components/Body/AdminArea";
import { DrawerDefault } from "@kentarepo/kcf-assets";
import { customerTemplateService } from "../../../services/costumerTemplate";
import { Administration } from "../Administration";
import { ContainerLeftMenu } from "../Administration/styles";
import { Container } from "./styles";
import { ICustomerTemplate, ISituation } from "./interface";



export function CustomerTemplate() {
  const [listCustomerTemplate, setListCustomerTemplate] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [template, setTemplate] = useState<string>("");
  const [id, setId] = useState<number | undefined>();
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [actived, setActived] = useState(true);
  const [type, setType] = useState<number | undefined>();
  const user = getValueFromlocalStorage("@Kenta:user");
  const [situation, setSituation] = useState<ISituation | null>(null);
  const [search, setSearch] = useState("");


  const [fieldError, setFieldError] = useState<{
    type: boolean;
    title: boolean;
    description: boolean;
    template: boolean;
  }>({
    type: false,
    title: false,
    description: false,
    template: false,
  });

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }
  const filteredData = listCustomerTemplate.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  useEffect(() => {
    (async () => {
      setLoading(true)
      await onLoadList();
      setLoading(false)
    })()
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    setLoading(true)
    const { success, body, message } =
      await customerTemplateService.getAllByCustomerId({
        customerId,
        active: situation,
        type: null,
      });

    if (success) {
      const templateList = body ? body.map((element: ICustomerTemplate) => ({
        ...element,
        name: element?.title,
      })) : [];

      setListCustomerTemplate(templateList);
    } else {
      addToast({
        type: "warning",
        title: message,
      });
    }
    dispatch(hideLoading());
    setLoading(false)
    return [];
  }

  function clear() {
    setIsEdit(false);
    setTitle("");
    setType(undefined);
    setDescription("");
    setTemplate("");
    setKeywords([]);
    setOpenModal(false);
    setId(undefined);
    setFieldError({
      type: false,
      template: false,
      description: false,
      title: false,
    });
    setActived(true);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }


  function onClickProcedureCustomerSource({
    active,
    id,
    keywords,
    template,
    title,
    type,
    description,
  }: ICustomerTemplate) {
    setId(id);
    setIsEdit(true);
    setTitle(title);
    setDescription(description ?? "");
    setActived(active);
    setTemplate(template);
    setKeywords(keywords ? keywords?.split(";") : []);
    setType(type);
    setOpenModal(true);
  }

  function createBody(): any {
    return {
      id: id ? id : undefined,
      customerId: customerId,
      type: type,
      title: title,
      description: description,
      template: template,
      keywords: keywords ? keywords.join(";") : null,
      active: actived,
      createIn: new Date(),
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function postTemplate() {
    dispatch(showLoading());
    if (!title || !type || !template || !description) {
      addToast({
        type: "warning",
        title: "Template",
        description:
          "Para criar um template é necessário preencher os campos destacados.",
      });
      dispatch(hideLoading());

      return setFieldError({
        template: !template,
        title: !title,
        description: !description,
        type: !type,
      });
    }

    if (type === 2 && !keywords.includes("Transcription")) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "É necessário adicionar a palavra chave <<Transcription>> para transcrição automática",
      });
      return dispatch(hideLoading());
    }

    const existName = stringFormat.verifyExistNameInArray(
      listCustomerTemplate,
      title
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return dispatch(hideLoading());
    }

    dispatch(showLoading());

    setOpenModal(false);
    const { success, message } =
      await customerTemplateService.createTemplate(createBody() as any);
    if (success) {
      addToast({
        type: "success",
        title: "Template",
        description: "O template foi criada com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!success) {
        addToast({
          type: "error",
          title: "Erro",
          description: message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao atualizar o template",
        });
      }
    }

    setLoading(false)
    dispatch(hideLoading());
  }

  async function putTemplate() {
    setLoading(true)
    dispatch(showLoading());
    if (!title || !type || !template || !description) {
      addToast({
        type: "warning",
        title: "Origem do procedimento",
        description:
          "Para atualizar um template é necessário preencher os campos destacados.",
      });
      setLoading(false)
      dispatch(hideLoading());

      return setFieldError({
        template: !template,
        title: !title,
        description: !description,
        type: !type,
      });
    }

    if (type === 2 && !keywords.includes("Transcription")) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "É necessário adicionar a palavra chave <<Transcription>> para transcrição automática",
      });
      setLoading(false)
      return dispatch(hideLoading());
    }

    const existName = stringFormat.verifyExistNameInArray(
      listCustomerTemplate,
      title,
      id
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      setLoading(false)
      return dispatch(hideLoading());
    }
    setLoading(true)
    dispatch(showLoading());

    const { success, message } = await customerTemplateService.updateTemplate(
      createBody()
    );
    if (success) {
      addToast({
        type: "success",
        title: "Template",
        description: "O template foi atualizada com sucesso.",
      });

      clear();
      await onLoadList();
    } else {
      addToast({
        type: "error",
        title: "Erro",
        description: message,
      });
    }

    setLoading(false)
    dispatch(hideLoading());
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 0:
        return setSituation(false);
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(null);
      default:
        return null;
    }
  };

  return (
    <Container>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        loading={loading}
        title={"Templates"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        list={filteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => { }}
        onClick={(item: any) => onClickProcedureCustomerSource(item)}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.CUSTOMER_TEMPLATE}
        searchText={search}
      />
      <DrawerDefault
        title={isEdit ? "Editar template" : "Criar template"}
        openDrawer={openModal}
        setOpenDrawer={() => clear()}
        onClose={() => clear()}
        onApply={isEdit ? putTemplate : postTemplate}
        paddingleft={1}
        paddingright={1}
        paddingbotton={180}
      >
        <CreateEditCustomerTemplate
          isEdit={isEdit}
          setTitle={setTitle}
          title={title}
          setDescription={setDescription}
          description={description}
          actived={actived}
          fieldError={fieldError}
          setFieldError={setFieldError}
          setTemplate={setTemplate}
          setKeywords={setKeywords}
          keywords={keywords}
          setActived={() => setActived(!actived)}
          setType={setType}
          type={type}
          template={template}
        />
      </DrawerDefault>
    </Container>
  );
}
