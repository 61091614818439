import React from "react";
import { TextForgotPasswordInfo } from "./styles";
import { BaseLabel, GeneralPasswordInput, StrongBar, strongLevel } from "@kentarepo/kcf-assets";
import { PhotoWebCam } from "../../photo";
import { ToastMessage } from "../../../hooks/toast";

export const passwordHTML = (
  currentPassword: string,
  setCurrentPassword: (event: string) => void,
  newPassword: string,
  onChangeNewPassword: (event: string) => void,
  confirmNewPassword: string,
  setNewConfirmPassword: React.Dispatch<React.SetStateAction<string>>,
  showPassword: boolean,
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>,
  errors: boolean,
  errorsCurrentPassword: boolean,
  statusPassword: strongLevel
) => {
  return (
    <>
      <TextForgotPasswordInfo>
        <BaseLabel
          fontSize="18px"
          fontWeight="700"
          marginBottom="0px"
          marginTop="0px"
          marginLeft="0px"
          marginRight="0px"
          children={"Redefinir senha"}
          
        />
      </TextForgotPasswordInfo>
      <TextForgotPasswordInfo paddingTop="28px">
        <BaseLabel
          fontSize="14px"
          fontWeight="500"
          marginBottom="0px"
          marginTop="0px"
          marginLeft="0px"
          marginRight="0px"
          children={
            "Utilize maiúsculas, minúsculas, números e um dos caracteres especiais (@ & $ % #) para criar uma senha forte."
          }
        />
      </TextForgotPasswordInfo>
      <TextForgotPasswordInfo marginTop="-15px !important">
        <GeneralPasswordInput
          name="password"
          label="Sua senha atual"
          value={currentPassword}
          onChange={(e: string) => setCurrentPassword(e)}
          handleClickShowPassword={() => setShowPassword(!showPassword)}
          showPassword={showPassword}
          error={errorsCurrentPassword}
          marginLeft="0"
          height="51.5px"
          marginBottom="20px"
        />
      </TextForgotPasswordInfo>
      <TextForgotPasswordInfo marginTop="-15px !important">
        <GeneralPasswordInput
          name="password"
          label="Digite a nova senha"
          value={newPassword}
          onChange={(e: string) => onChangeNewPassword(e)}
          handleClickShowPassword={() => setShowPassword(!showPassword)}
          showPassword={showPassword}
          error={errors}
          marginLeft="0"
          height="51.5px"
          marginBottom="20px"
        />
      </TextForgotPasswordInfo>
      <TextForgotPasswordInfo paddingTop="2px">
        <StrongBar strongLevel={statusPassword} />
      </TextForgotPasswordInfo>
      <TextForgotPasswordInfo paddingTop="3px">
        <GeneralPasswordInput
          name="confirmPassword"
          label="Repita a nova senha"
          value={confirmNewPassword}
          onChange={setNewConfirmPassword}
          handleClickShowPassword={() => setShowPassword(!showPassword)}
          showPassword={showPassword}
          error={errors}
          marginLeft="0"
          height="51.5px"
          marginBottom="30px"
        />
      </TextForgotPasswordInfo>
    </>
  );
};

export const photoHTML = (
  img: any,
  setImg: (value: any) => unknown,
  setFileImg: (value: File) => unknown,
  userStorage: string,
  storageBaseUrl: string,
  userPicture: string,
  containerNameUnit: string,
  addToast: (messages: Omit<ToastMessage, "id">) => void
) => {
  return (
    <>
      <PhotoWebCam
        img={img}
        setImg={setImg}
        setFileImg={setFileImg}
        userStorage={userStorage}
        storageBaseUrl={storageBaseUrl}
        userPicture={userPicture}
        containerNameUnit={containerNameUnit}
        addToast={addToast}
      />
    </>
  );
};
