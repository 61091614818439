import { AxiosError } from "axios";
import api from "../api";
import { returnResponse } from "../utils";
import { UserCustomerProfileResponse } from "./types";

export type IAddUserCustomerDistrict = {
  id?: number;
  userId: number;
  placeId: number;
  userCustomerProfileId: number;
  expireIn: string | null;
  active: boolean;
  device: string;
  ip: string;
  logPoliceUnitId: number;
  logUserId: number;
};

export const UserCustomerProfileService = {
  getAllByUserCustomer: async (
    authToken: string | null,
    userCustomerId: number
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de perfil sem estar autenticado.",
      };
    }

    if (!userCustomerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de perfil sem o identificador do perfil",
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { UserCustomerId: userCustomerId },
      };
      const { data } = await api.get<UserCustomerProfileResponse>(
        `/UserCustomerProfile/GetUserCustomerProfileByUserCustomerId?UserCustomerId=${userCustomerId}`,
        config
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors?.[0]?.message ?? "Error",
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  /**
   *
   * @param authToken token of auth
   * @param customerId
   * @param userCustomerProfileId
   * @returns
   */


};
