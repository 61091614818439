/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { Container, Content } from "./styles";
import { IListRecorded, WebSocketHearingResponse } from "./interface";
import { statusRecorded, ticksToTime } from "../LeftList/util";
import { CreateRecord } from "../../cruds/Drawer/CreateRecord";
import { AxiosInstance } from "axios";
import { PageHeader, RecordCard, SearchEmpty } from "@kentarepo/kcf-assets";
import { SessionLocalStorage } from "@kentarepo/kcf-templates";
import { formatDateTime, recordersServices } from "@kentarepo/kcf-services";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useQuery } from "../../../utils/get";
import { hearingFileService } from "../../../services/hearingFile";
import { LoadSpinner } from "../../LoadSpinner";
import useWebsocket from "react-use-websocket";
import { Hearing } from "../../../pages/app/audiences/interface";

const label = "Gravações";

export const ListRecorded: React.FC<IListRecorded> = ({
  audienceId,
  placeId,
  api,
  selectedRecordId,
  setData,
  recordList,
  setSelectRecord,
  addToast,
  disabled,
  showAddButton = true,
}) => {
  const [recordedList, setRecordedList] = useState<any[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [date, setDate] = useState<string | undefined>();
  const [time, setTime] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processingRecord, setProcessingRecord] = useState<Hearing>();
  
  const { read, create } = usePermissions(FORMS_HEARING.HEARING_FILE);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    (async () => {
      const hearingFileId = query.get("hearingFileId");
      setRecordedList([]);
      dispatch(showLoading());
      if (audienceId && placeId) {
        const apiService = recordersServices(api);
        const { data, success } = await apiService.getListByAudienceId({
          audienceId,
        });

        if (success) {
          if (setData) {
            setData(data);
          }
          setRecordedList(data?.hearingInProgress);

          if (hearingFileId) {
            const hearingFileFinded: any = data?.hearingInProgress?.find(
              (x) => x.id === Number(hearingFileId)
            );

            if (hearingFileFinded && setSelectRecord)
              setSelectRecord(hearingFileFinded);
          }
        }
        dispatch(hideLoading());
      }
    })();
  }, [audienceId, placeId, openDrawer]);

  const handleGetUrlByHearingFileId = async (item: any) => {
    setLoading(true);
    try {
      const url = await hearingFileService.getUrlById(item.id);

      item.filename = url.body.filename;

      if (setSelectRecord) {
        setSelectRecord(item);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };



  //#region web Socket

  const WS_URL_HEARINGS = `${import.meta.env.VITE_APP_WSS_BASE_URL
    }/hearingFile`;
  const { sendMessage: sendMessageHearing } = useWebsocket(WS_URL_HEARINGS, {
    onMessage: (event: MessageEvent<any>) => {
      if (event.data) {
        const webSocketHearingFilesJson = JSON.parse(event.data);
        const webSocketHearingFiles =
          webSocketHearingFilesJson as WebSocketHearingResponse;

        if (webSocketHearingFiles.OK === true) {
          if (webSocketHearingFiles.Data) {
            const hearingInProgress =
              webSocketHearingFiles.Data.HearingInProgress;
            const hearingInProgressMap =
              hearingInProgressMapper(hearingInProgress);
            setRecordedList(hearingInProgressMap);

            if (setData) {
              //attendants
              const attendantsWebSocket = webSocketHearingFiles.Data.HearingAttendants;
              const attendantsWebSocketMap = hearingAttendantMapper(attendantsWebSocket);

              //schadelued
              const hearingScheduledWebSocket = webSocketHearingFiles.Data.HearingScheduled;
              const hearingScheduledWebSocketMap = hearingScheduledMapper(hearingScheduledWebSocket);

              setData({ ...recordList, hearingAttendants: attendantsWebSocketMap, hearingScheduled: hearingScheduledWebSocketMap });
            }
          }
        }
      }
    },
    onOpen: () => { },
    onClose: () => { },
    shouldReconnect: () => true,
    reconnectInterval: 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (audienceId && audienceId !== undefined && audienceId !== null) {
        sendMessageHearing(audienceId.toString());
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [audienceId]);

  useEffect(() => {
    if (!recordedList?.length) return;
    const currentItem = recordedList.find((item) => item.id === selectedRecordId);

    if (currentItem?.status !== 13) {
      setProcessingRecord(currentItem);
      return;
    }

    if(processingRecord) {
      handleGetUrlByHearingFileId(currentItem);
      setProcessingRecord(undefined);
    }
  }, [recordedList]);

  const hearingInProgressMapper = (hearingInProgress: any) => {
    return hearingInProgress.map((item: any) => ({
      id: item.Id,
      hearingId: item.HearingId,
      type: item.Type,
      title: item.Title,
      filename: item.Filename,
      recordId: item.RecordId,
      duration: item.Duration,
      size: item.Size,
      fileOrder: item.FileOrder,
      hash: item.Hash,
      createIn: item.CreateIn,
      updateIn: item.UpdateIn,
      upload: item.Upload,
      watermark: item.Watermark,
      platform: item.Platform,
      status: item.Status,
      download: item.Download,
      expectedDate: item.ExpectedDate,
      startDate: item.StartDate,
      finishDate: item.FinishDate,
      userId: item.UserId,
    }));
  };

  const hearingAttendantMapper = (attendants: any) => {
    return attendants.map((item: any) => ({
      id: item.Id,
      name: item.Name,
      role: item.Role,
      attendantId: item.AttendantId,
      attendantRoleId: item.AttendantRoleId,
      extraData: item.ExtraData,
    }));
  };

  const hearingScheduledMapper = (data: any) => {
    return data.map((item: any) => ({
      id: item.Id,
      hearingId: item.HearingId,
      type: item.Type,
      title: item.Title,
      filename: item.Filename,
      recordId: item.RecordId,
      duration: item.Duration,
      size: item.Size,
      fileOrder: item.FileOrder,
      hash: item.Hash,
      createIn: item.CreateIn,
      updateIn: item.UpdateIn,
      upload: item.Upload,
      watermark: item.Watermark,
      platform: item.Platform,
      status: item.Status,
      download: item.Download,
      expectedDate: item.ExpectedDate,
      startDate: item.StartDate,
      finishDate: item.FinishDate,
      userId: item.UserId,
    }));
  };

  //#endregion

  return (
    <Fragment>
      <Container>
        <PageHeader
          quantity={read ? recordedList?.length : 0}
          id="header"
          label={label}
          background="secondary"
          addClick={
            audienceId && showAddButton && create
              ? () => setOpenDrawer(true)
              : undefined
          }
          disabled={disabled}
        />

        {read ? (
          <Content>
            {recordedList?.length ? (
              recordedList.map((item, index) => (
                <RecordCard
                  key={index}
                  selected={item?.id === selectedRecordId}
                  duration={ticksToTime(item.duration)}
                  title={formatDateTime.getFullDate(item.startDate)}
                  subTitle={formatDateTime.getFullTime(item.startDate)}
                  labelStatus={statusRecorded(item.status).status}
                  type={statusRecorded(item.status).type}
                  onClick={
                    !disabled && setSelectRecord
                      ? () => handleGetUrlByHearingFileId(item)
                      : undefined
                  }
                  disabled={read ? disabled : true}
                />
              ))
            ) : (
              <SearchEmpty
                textMessage="Nenhuma gravação ainda"
                typeIcon="video"
                marginTop="40px"
              />
            )}
          </Content>
        ) : (
          <SearchEmpty
            textMessage="Sem permissão para seu perfil"
            typeIcon="avatar"
            marginTop="40px"
          />
        )}
      </Container>
      <CreateRecord
        addToast={addToast}
        title={label.toLocaleLowerCase()}
        api={api as AxiosInstance}
        isOpen={create ? openDrawer : false}
        data={{
          hearingId: audienceId,
          userId: SessionLocalStorage.getUserCustomerId() || 0,
        }}
        isEdit={false}
        setClose={() => setOpenDrawer(false)}
        date={date}
        setDate={setDate}
        time={time}
        setTime={setTime}
      />
      <LoadSpinner loading={loading} />
    </Fragment>
  );
};
