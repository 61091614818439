import React from "react";
import { IChangeLogProps } from "./interfaces";
import { DrawerDefault, SearchEmpty } from "@kentarepo/kcf-assets";
import { handleChangeLogHtml } from "./constants";
import { LoadSpinner } from "../../../features/LoadSpinner";

export const ChangeLog: React.FC<IChangeLogProps> = ({
  changeLogModal,
  setChangeLogModal,
  changeLogData,
}) => {
  return (
    <DrawerDefault
      children={
        changeLogData !== undefined ? (
          handleChangeLogHtml(changeLogData)
        ) : (
          <>
            <SearchEmpty
              textMessage={"Nenhuma versão disponível ainda"}
              typeIcon={"file"}
            />
            <LoadSpinner loading={true} />
          </>
        )
      }
      openDrawer={changeLogModal}
      setOpenDrawer={setChangeLogModal}
      onClose={() => setChangeLogModal(false)}
      title={"O que há de novo"}      
      haveFooter={false}
      paddingright={0}
      paddingleft={14}
    />
  );
};
