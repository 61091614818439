import { Container, ContainerLoading } from "./styles";
import { MenuTabsKenta } from "@kentarepo/kcf-assets";
import { menu } from "./constants";
import { IMenuTab } from "./interface";
import Loading from "react-loading";
import { useEffect, useState } from "react";
import { SystemConfigurationService } from "../../../services/systemConfiguration";
import { LogOperation } from "../../../utils/entities/logOperation";

const RightTemplate: React.FC<IMenuTab & { videoRef: React.RefObject<HTMLVideoElement> }> = ({
  defaultPage = 0,
  transcription,
  summary,
  ata,
  mark,
  setPage,
  loadingAba,
  videoRef
}) => {

  const [isAISummaryEnabled, setIsAISummaryEnabled] = useState<boolean>(false);
  const [isSynchronizedTextEnabled, setIsSynchronizedTextEnabled] = useState<boolean>(false);

  const fetchConfigurations = async () => {
    try {
      const response = await SystemConfigurationService.getAllByCustomer(
        LogOperation.getCustomerId()
      );

      if (response.status) {
        const transcriptionProvider = response.data.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (config: any) => config.name === "TranscriptionProvider"
        );

        const aiSummaryConfig = response.data.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (config: any) => config.name === "TranscriptionSummary"
        );

        setIsSynchronizedTextEnabled(transcriptionProvider?.value === "4");
        setIsAISummaryEnabled(aiSummaryConfig?.value === "1");
      }
    } catch (error) {
      console.error("Erro ao buscar configurações:", error);
    }
  };

  useEffect(() => {
    fetchConfigurations();    
  }, []);

  const menuData = menu({ transcription, summary, mark, ata, videoRef, isAISummaryEnabled, isSynchronizedTextEnabled });

  return (
    <Container>
      {loadingAba && (
        <ContainerLoading>
          <Loading width={40} type="spin" color="#000" />
        </ContainerLoading>
      )}
      <MenuTabsKenta
        bodyList={menuData.bodyList}
        menuList={menuData.menusList}
        pageDefault={defaultPage}
        setPage={setPage}
      />
    </Container>
  );
};

export default RightTemplate;
