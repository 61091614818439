/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { IListProcedures } from "./interface";
import { CardLeftItem, PageHeader, SearchEmpty } from "@kentarepo/kcf-assets";
import { audiencesServices } from "@kentarepo/kcf-services";
import { Container, Content } from "./styles";
import { status } from "./util";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";

export const LeftList: React.FC<IListProcedures> = ({
  placeId,
  principalLabel,
  onClick,
  audienceSelected,
  api,
  addClick,
  updateAudiences,
  backgroundHeader = "primary",
  audiences,
  filterClick,
  disabledFilter,
  isFilter,
}) => {
  const apiAudiencesServices = audiencesServices(api);

  const { read, create } = usePermissions(FORMS_HEARING.HEARING);

  useEffect(() => {
    (async () => {
      // se tiver item na lista audiences verificar o placeId do primeiro é diferente de placeId
      if (
        (placeId && !audiences?.length) ||
        (audiences && audiences?.length && audiences[0].placeId !== placeId)
      ) {
        const { data, success } = await apiAudiencesServices.getListByPlaceId({
          placeId: placeId,
        });

        if (success) {
          updateAudiences(data as any); // Atualize as audiências no componente pai
        }
      }
    })();
  }, [placeId]);

  return (
    <Container>
      <PageHeader
        id="header"
        label={principalLabel}
        background={backgroundHeader}
        addClick={create ? addClick : undefined}
        filterClick={read ? filterClick : undefined}
        deleteClick={() => {}}
        disabledDeleteButton
        disabledFilterButton={disabledFilter}
        isFilter={isFilter}
      />
      {read ? (
        <Content id="audience_container">
          {audiences?.length ? (
            audiences?.map((audience, index: number) => (
              <CardLeftItem
                id={`audience_card_${index}`}
                key={index}
                date={new Date(audience.expectedDate)}
                labelStatus={status(audience.status).status}
                userName={audience.extraContent}
                title={String(audience.procedureNumber)}
                subTitle={audience?.description || "não informado"}
                type={status(audience.status).type}
                selected={audience?.id === audienceSelected?.id}
                onClick={() => onClick(audience)}
              />
            ))
          ) : (
            <SearchEmpty
              textMessage="Nenhuma audiência"
              typeIcon="file"
              marginTop="40px"
            />
          )}
        </Content>
      ) : (
        <SearchEmpty
          textMessage="Sem permissão para seu perfil"
          typeIcon="avatar"
          marginTop="40px"
        />
      )}
    </Container>
  );
};
