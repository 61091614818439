import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ApexPieChartProps {
    series: number[];
    labels: string[];
}

const ApexPieChart: React.FC<ApexPieChartProps> = ({ series, labels }) => {
    const pieChartOptions: ApexOptions = {
        chart: {
            type: 'pie',
            width: '100%',
            height: '100%',
            animations: {
                enabled: true
            },
        },
        labels: labels,
        colors: ['#337AB7', '#8DC572'],

        legend: {
            show: true,
            fontFamily: `'Roboto', sans-serif`,
            fontSize: '14px',
            position: 'top',
            horizontalAlign: 'center',
            labels: {
                useSeriesColors: false
            },
            markers: {
                size: 12,
                strokeWidth: 0
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5,
                },

            }

        },
        dataLabels: {
            enabled: true,
            formatter: function (_val: any, opts: { w: { config: { series: { [x: string]: any; }; }; }; seriesIndex: string | number; }) {
                return opts.w.config.series[opts.seriesIndex];
            },
            style: {
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 'bold',
            },
            dropShadow: {
                enabled: false
            }
        },
    };

    return (
        <div id="chart" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}>
            <ReactApexChart
                options={pieChartOptions}
                series={series}
                type="pie"
            />
        </div>
    );
};

export default ApexPieChart;
