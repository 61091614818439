import React from "react";
import { TextForgotPasswordInfo } from "./styles";
import {
  BaseLabel,
  Button,
  GeneralPasswordInput,
  Grid,
  StrongBar,
  strongLevel,
} from "@kentarepo/kcf-assets";

export const ChangePasswordStepOne = (
  password: string,
  onChangePassword: (event: string) => void,
  confirmPassword: string,
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>,
  showPassword: boolean,
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>,
  errors: boolean,
  statusPassword: strongLevel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (e: any) => Promise<void>
) => {
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid alignContent="center">
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "390px",
            marginTop: "267px",
          }}
        >
          <TextForgotPasswordInfo>
            <BaseLabel
              fontSize="18px"
              fontWeight="700"
              marginBottom="0px"
              marginTop="0px"
              marginLeft="0px"
              marginRight="0px"
              children={"Crie uma nova senha"}
            />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo paddingTop="28px">
            <BaseLabel
              fontSize="14px"
              fontWeight="500"
              marginBottom="0px"
              marginTop="0px"
              marginLeft="0px"
              marginRight="0px"
              children={
                "Utilize maiúsculas, minúsculas, números e um dos caracteres especiais (@ & $ % #) para criar uma senha forte."
              }
            />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo marginTop="-15px !important">
            <GeneralPasswordInput
              name="password"
              label="Digite a senha"
              value={password}
              onChange={(e: string) => onChangePassword(e)}
              handleClickShowPassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
              error={errors}
              marginLeft="0"
              height="51.5px"
              marginBottom="20px"
            />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo paddingTop="2px">
            <StrongBar strongLevel={statusPassword} />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo paddingTop="3px">
            <GeneralPasswordInput
              name="confirmPassword"
              label="Repita a senha"
              value={confirmPassword}
              onChange={setConfirmPassword}
              handleClickShowPassword={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
              error={errors}
              marginLeft="0"
              height="51.5px"
              marginBottom="30px"
            />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo paddingTop="5px">
            <Button
              id="sign-in_button"
              type="submit"
              marginBottom="16px"
              backgroundColor="#1D9BF0"
              borderColor="#1D9BF0"
              textColor="white"
              backgroundHoverColor="#0D77BF"
              borderHoverColor="#0D77BF"
              onClick={(e:any) => {
                handleSubmit(e);
              }}
            >
              Continuar
            </Button>
          </TextForgotPasswordInfo>
        </Grid>
      </Grid>
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChangePasswordStepTwo = (navigate: any, navigateTo: string) => {
  return (
    <form>
      <Grid alignContent="center">
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "390px",
            marginTop: "315px",
          }}
        >
          <TextForgotPasswordInfo>
            <BaseLabel
              fontSize="14px"
              fontWeight="700"
              marginBottom="0px"
              marginTop="0px"
              marginLeft="0px"
              marginRight="0px"
              children={"Sua senha foi alterada com sucesso!"}
            />
          </TextForgotPasswordInfo>
          <TextForgotPasswordInfo paddingTop="30px">
            <Button
              id="sign-in_button"
              type="submit"
              marginBottom="16px"
              backgroundColor="#1D9BF0"
              borderColor="#1D9BF0"
              textColor="white"
              backgroundHoverColor="#0D77BF"
              borderHoverColor="#0D77BF"
              onClick={() => navigate(navigateTo)}
            >
              Voltar ao login
            </Button>
          </TextForgotPasswordInfo>
        </Grid>
      </Grid>
    </form>
  );
};
