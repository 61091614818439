/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { baseURL } from "../api";
import { getValueFromlocalStorage } from "../utils/sessionStorageEncrypt";

interface UploadFileInBlockProps {
  file: File;
  endpoint: string;
  hearingFileId?: number;
  isMain?: boolean;
  onProgress?: (percentage: number) => void;
}

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  for (let i = 0; i < bytes.length; i += 10000) {
    binary += String.fromCharCode.apply(
      null,
      bytes.subarray(i, i + 10000) as unknown as number[]
    );
  }
  return btoa(binary);
};

const UploadFileInBlock = async ({
  file,
  endpoint,
  hearingFileId,
  isMain,
  onProgress,
}: UploadFileInBlockProps) => {
  const token = getValueFromlocalStorage("@Kenta:access_Token");
  const fileName = file.name;
  const isMainRecord = isMain ? `&mainVideo=${isMain}` : "";
  const hearingFileIdPath = hearingFileId
    ? `&hearingFileId=${hearingFileId}`
    : "";

  const realChunkSize = 4 * 1024 * 1024; // 4MB
  const totalSize = file.size;
  const blockNumber = Math.ceil(totalSize / realChunkSize);

  const config = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  };

  for (let index = 0; index < blockNumber; index++) {
    const start = index * realChunkSize;
    const end = Math.min(start + realChunkSize, totalSize);
    const blobChunk = file.slice(start, end);

    const base64Chunk: string = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const base64 = arrayBufferToBase64(buffer);
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blobChunk);
    });

    let paddedChunk = base64Chunk;
    if (paddedChunk.length % 4 !== 0 && index === blockNumber - 1) {
      while (paddedChunk.length % 4 !== 0) {
        paddedChunk += "=";
      }
    }

    try {
      const { data } = await axios.post(
        `${baseURL}/HearingFile/${endpoint}?fileName=${fileName}&blockNumber=${
          index + 1
        }&blocks=${blockNumber}${hearingFileIdPath}${isMainRecord}`,
        JSON.stringify(paddedChunk),
        config
      );

      const progress = Math.round(((index + 1) / blockNumber) * 100);
      if (onProgress) {
        onProgress(progress);
      }

      if (data.ok) return data;
    } catch (error: any) {
      console.error("Erro ao enviar o bloco:", error);
      return error.response?.data || { error: "Erro desconhecido" };
    }
  }
};

export default UploadFileInBlock;
