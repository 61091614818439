/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import api, { baseBlob } from "../../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { HearingService } from "../../../services/hearing";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { useToast } from "../../../hooks/toast";
import CreateAudience from "./CreateAudience";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useDispatch, useSelector } from "react-redux";
import { DrawerDefault, ModalDefault } from "@kentarepo/kcf-assets";

import { setAttendantsList } from "../../../store/modules/procedure/actions";
import { ProcedureAttendant } from "../../../components/ProcedureAttendant";
import {
  IHearingFileBody,
  IHearingFileDownloads,
  stringFormat,
} from "@kentarepo/kcf-services";
import {
  AttendandoViculeService,
  AttendantService,
  procedureCustomerAttendantService,
} from "../../../services/attendant";
import * as KentaTemplates from "@kentarepo/kcf-templates";
import {
  CreateRecordEdit,
  ShowModalDeleteAlert,
  checkInputsFilled,
  clearAudienceFilter,
  filterSearch,
  getFirstRecord,
  handleChangeHearingsFromWebSocket,
  handleItemClicked,
  handleOpenDrawerDownloads,
  updateSchaduledList,
  updateStatusHearing,
} from "./constants";
import { getActualTime } from "../../../services/utils/dateTime";
import { format } from "date-fns";
import { IOptionsFilterStatus } from "@kentarepo/kcf-templates";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { usePermissions } from "../../../hooks/permission";
import { RightTemplate } from "../../../features/RightTemplate";
import CreateAttachmentTemplate from "../../../features/CreateAttachmentTemplate";
import { LeftList } from "../../../features/hearingRecords/LeftList";
import { ListRecorded } from "../../../features/hearingRecords/ListRecorded";
import ValidationModal from "../../../features/ValidationModal";
import { SystemConfigurationService } from "../../../services/systemConfiguration";
import { AdminDashboard } from "../../../features/AdminDashboard";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../themes";
import { HearingFilter } from "../../../features/Modal/HearingFilter";
import { DownloadsByHearingFile } from "../../../features/DownloadsByHearingFile";
import { useUploadingFile } from "../../../features/ValidationModal/context";
import { RightAudienceComponent } from "../../../features/RightAudienceComponent";
import { LoadSpinner } from "../../../features/LoadSpinner";
import { ServiceIntegrationNotification } from "../ServiceIntegrationNotification";
import { IHearingAttendant } from "../../../components/ItemDetailLine/interface";
import { hearingFileService } from "../../../services/hearingFile";
import { LogOperation } from "../../../utils/entities/logOperation";
import { useQuery } from "../../../utils/get";
import { CreateEditAttendantInAudience } from "../Attendant/CreateEditInAudience";
import useWebsocket from "react-use-websocket";

export const Audiences: React.FC = () => {
  const customization = useSelector((state: any) => state.customization);
  //==================================================================================================================== States

  const [audience, setAudience] = useState<any>(undefined);
  const [audienceType, setAudienceType] = useState<any>("");
  const { userCustomerProfile } = useSelector((store: any) => store.profile);
  const { uploadingFile, setUploadingFile, loadingUpload } = useUploadingFile();

  const { editItem } = useSelector((store: any) => store.attachment);

  const { managerHearingClassified, notify } = usePermissions(
    FORMS_HEARING.HEARING
  );

  const { export: exportHearinDownloadsFiles } = usePermissions(
    FORMS_HEARING.HEARING_FILE
  );

  const [nameAttendant, setNameAttendant] = useState<string>("");
  const [active, setActive] = useState(true);

  const [record, setRecord] = useState<any>(null);
  const [listRecord, setListRecord] = useState<any | any[]>(null);
  const { addToast } = useToast();
  const authToken = getValueFromlocalStorage("@Kenta:access_Token");

  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalValidation, setOpenModalValidation] = useState(false);
  const [openModalNotify, setOpenModalNotify] = useState(false);
  const [date, setDate] = useState<string | null>(null);
  const [hours, setHours] = useState<number | null>(null);
  const [isConfidential, setIsConfidential] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editTypeSelected, setEditTypeSelected] = useState<any>("");
  const [nameAudience, setNameAudience] = useState<string>("");
  const [audiences, setAudiences] = useState<KentaTemplates.IAudience[]>([]);
  const dispatch = useDispatch();
  const user = getValueFromlocalStorage("@Kenta:user");
  const [objectType, setObjectType] = useState<any | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);

  const [selected, setSelected] = useState<number | null>();
  const [attendantList, setAttendantList] = useState<any[]>([]);
  const [selectedCustomerAttendantId, setSelectedCustomerAttendantId] =
    useState<number | undefined>();
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [fieldErrorCreate, setFielErrorCreate] = useState<boolean>(false);
  const [fieldErrorParticipant, setFieldErrorParticipant] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [attendantRoleName, setAttendantRoleName] = useState<string>("");
  const [attendantRoleId, setAttendantRoleId] = useState<number>();
  const [_validateVideo, setValidateVideo] = useState(true);
  const [createAudienceMask, setCreateAudienceMask] = useState("");

  const [errorFields, setErrorFields] = useState({
    nameAudience: false,
    hours: false,
    date: false,
    audienceType: false,
  });

  const [listAttendantInHearingFile, setListAttendantInHearingFile] = useState<
    Array<IHearingAttendant>
  >([]);

  //==================================================================================================================== Downloads

  const [openDownloads, setOpenDownloads] = useState<boolean>(false);
  const [listDownloads, setListDownloads] = useState<
    Array<IHearingFileDownloads>
  >([]);

  const [openAttachmentDrawer, setOpenAttachmentDrawer] =
    useState<boolean>(false);

  //==================================================================================================================== Drag And Drop Validated File

  const [file, setFile] = useState<File | null>(editItem ? editItem : null);
  const [fileError, setFileError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_typeError, setTypeError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_type, setType] = useState<any>();

  //==================================================================================================================== Delete hearing File
  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);
  const [hearingFileIdToDelete, setHearingFileIdToDelete] = useState<number>(0);

  //==================================================================================================================== Edit Hearing File
  const [openEditRecord, setOpenEditRecord] = useState<boolean>(false);
  const [hearingFileToEdit, setHearingFileToEdit] = useState<
    IHearingFileBody | undefined
  >(undefined);

  const [dateHearingFile, setDateHearingFile] = useState<string | null>();
  const [timeHearingFile, setTimeHearingFile] = useState<string | undefined>();

  const [recordEditedReturn, setRecordEditedReturn] =
    useState<IHearingFileBody>();

  //==================================================================================================================== FILTER HEARING / AUDIENCE
  const [openHearingFilter, setOpenHearingFilter] = useState<boolean>(false);

  const [hearingNumber, setHearingNumber] = useState<string>("");
  const [startDate, setStartDate] = useState<string | null | undefined>("");
  const [finishDate, setFinishDate] = useState<string | null | undefined>("");
  const [activeFilter, setActiveFilter] = useState<boolean>(true);
  const [valueOptionDropDown, setValueOptionDropDown] = useState<any>();
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [clearDatesFilter, setClearDatesFilter] = useState<number>(0);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [finishDateError, setFinishDateError] = useState<boolean>(false);
  const query = useQuery();

  const options: Array<IOptionsFilterStatus> = [
    { label: "Agendado", value: "A" },
    { label: "Encerrado", value: "E" },
    { label: "Em andamento", value: "G" },
    { label: "Inconsistente", value: "O" },
    { label: "Erro", value: "R" },
    { label: "Cancelado", value: "C" },
    { label: "Processando", value: "S" },
  ];

  //====================================================================================================================

  const navigate = useNavigate();
  const location = useLocation();

  const dataLocation = location.state;

  async function requestPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      stream.getTracks().forEach((track) => track.stop()); // Stop the tracks
      return true; // Permission granted
    } catch (error) {
      return false; // Permission denied or other error
    }
  }

  function clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }

    // Limpar o cache
    if ("caches" in window) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    // Limpar cookies
    const cookies2 = document.cookie.split(";");
    for (let i = 0; i < cookies2.length; i++) {
      const cookie = cookies2[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  const createHearingRecord = async (item: any) => {
    if (listAttendantInHearingFile.length <= 0) {
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "É necessário que tenha pelo menos um participante para prosseguir",
      });
      return;
    }

    const temPermissaoDeCamera = await requestPermission();
    if (!temPermissaoDeCamera) {
      addToast({
        type: "error",
        title: "Permitir câmera",
        description:
          "É necessário que seja permitido o uso da câmera para prosseguir",
      });
      return;
    }

    if (authToken && audience && audience.id) {
      clearAllCookies();

      const response = await HearingService.openHearingRecord(
        authToken,
        item.id
      );

      if (typeof response?.data?.message === "string") {
        const splitMessage = response.data.message.split("&");
        const deviceId = splitMessage[0];
        localStorage.setItem("deviceId", deviceId);
      }

      if (response.status) {
        hearingFileService.patch(
          "userId",
          item?.id,
          user ? JSON.parse(user)?.id : null
        );

        document.cookie = `${response?.data?.token}`;
        document.cookie = `${response?.data?.sessionToken}`;

        navigate(
          `/recording/una?hearingId=${item?.hearingId}&hearingFileId=${item?.id}`,
          {
            state: {
              ...audience,
              hearingId: item?.id,
              urlModerator: response?.data?.urlModerator,
              urlAttendee: response?.data?.urlAttendee,
              videoRoomId: response?.data?.videoRoom?.id,
              authToken: response?.data?.token,
              deviceToken: response?.data?.sessionToken,
            },
          }
        );
      } else {
        addToast({
          type: "error",
          title: "Não foi possivel fazer a gravação",
          description: "Verifique se a Audiência foi criada corretamente",
        });
      }
    }
  };

  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else {
      return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
    }
  }

  const changeRecord = async (selected: any) => {
    if (isConfidential && !managerHearingClassified) {
      return addToast({
        type: "warning",
        title: "Não foi possivel visualizar a gravação",
        description: "Esta é uma audiência sigilosa ",
      });
    }
    // show/hide the right component
    if (record && record?.id === selected?.id && record.status === selected?.status) {
      return setRecord(null);
    }

    setRecord(null);
    setTimeout(() => {
      setRecord(selected);
    }, 5);
  };

  useEffect(() => {
    if (user) {
      const user_parsed = JSON.parse(user);
      const customerId = user_parsed?.customerId;

      SystemConfigurationService.getAllByCustomer(customerId).then(
        (response) => {
          const SystemConfigurationFilter = response?.data?.filter(
            (item: any) => item?.name === "ProcedureMask"
          );

          if (
            SystemConfigurationFilter === undefined ||
            SystemConfigurationFilter.length === 0
          ) {
            setCreateAudienceMask("");
          } else {
            setCreateAudienceMask(SystemConfigurationFilter[0]?.value);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    setRecord(null);
    setListRecord(null);
    setIsConfidential(!!audience?.isClassified);
  }, [audience?.id]);

  const formatExpectedDate = (date: any, hours: number) => {
    const formattedDate = date.toISOString().split("T")[0];
    const formattedTime = `${hours}:00:00`;
    return `${formattedDate}T${formattedTime}`;
  };

  const editAudience = async () => {
    try {
      const userId: any = user ? JSON.parse(user)?.id : null;

      if (!nameAudience || !date || !hours || !audienceType) {
        validFields(nameAudience, date, hours, audienceType);
        addToast({
          type: "warning",
          title: "Preencha os campos",
          description: "Para continuar é necessário preencher todos os campos.",
        });
        return dispatch(hideLoading());
      }
      const existingAudienceWithSameNumber = audiences.find(
        (aud) =>
          String(aud.procedureNumber) === String(nameAudience) &&
          aud?.id !== audience?.id
      );

      if (existingAudienceWithSameNumber) {
        addToast({
          type: "error",
          title: "Erro ao atualizar audiência",
          description:
            "Já existe uma audiência com esse número de procedimento.",
        });
        return;
      }

      const bodyData = {
        guid: audience.guid,
        id: audience?.id,
        userId: userId,
        active: active,
        placeId: userCustomerProfile?.placeId,
        hearingTypeId: audienceType,
        description: objectType ? objectType.name : audience.description || "",
        isClassified: isConfidential,
        expectedDate:
          date && hours ? formatExpectedDate(new Date(date), hours) : null,
        procedureNumber: nameAudience,
        status: audience.status,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      setLoading(true);
      await HearingService.putNewAudience(bodyData);
      const audienceId = audience?.id;
      onCloseModalUpdateAudience();
      updateAudiences({ audienceId, notClose: true });
      setOpenModal(false);
      dispatch(hideLoading());
      setLoading(false);

      addToast({
        type: "success",
        title: "Audiência atualizada com sucesso",
      });
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro ao atualizar audiência",
      });
    }
  };

  const validFields = (
    nameAudience: string | null,
    date: string | null,
    hours: number | null,
    audienceType: any
  ) => {
    setErrorFields({
      nameAudience: !nameAudience,
      date: !date,
      hours: !hours,
      audienceType: !audienceType,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isSubmitting) return;

      setIsSubmitting(true);
      await createAudience();

      setIsSubmitting(false);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      setIsSubmitting(false);
    }
  };

  const getAttedantListToSelect = async () => {
    const parserUser = user ? JSON.parse(user) : null;
    if (user && parserUser) {
      const { body, success } =
        await procedureCustomerAttendantService.getAttendantByProcedureRoleType(
          {
            hearingId: audience?.id,
          }
        );
      if (success && body && listRecord) {
        return setListRecord({ ...listRecord, hearingAttendants: body });
      }
    }
  };

  const getAttedantList = async () => {
  
    const { data, status } =
      await procedureCustomerAttendantService.getAllToBindByHearingId({
        hearingId: audience?.id,
      });
  
      if (status) {

        const linkedAttendantIds = listAttendantInHearingFile.map(
          (linked: IHearingAttendant) => Number(linked.attendantId)
        );
      
        const filteredAttendants = data.data.filter(
          (attendant: { id: number }) => !linkedAttendantIds.includes(Number(attendant.id))
        );
      
        setAttendantList(filteredAttendants);
        dispatch(setAttendantsList(filteredAttendants));
      }
         
  };
  

  useEffect(() => {
    setFieldError(false);
  }, [nameAttendant, attendantRoleId]);

  const onClickDesvincule = async () => {
    if (selectedCustomerAttendantId) {
      setShowModalConfirmation(false);
      setLoading(true);
      const { success, body } =
        await procedureCustomerAttendantService.unbinding(
          selectedCustomerAttendantId
        );
      setLoading(false);
      if (success && body) {
        addToast({
          title: "Participante desvinculado",
          description: body,
          type: "success",
        });

        const newListAttendantInHearingFile = listAttendantInHearingFile.filter(
          (x: any) => x?.id !== selectedCustomerAttendantId
        );
        setListAttendantInHearingFile(newListAttendantInHearingFile);

        await getAttedantList();
        await getAttedantListToSelect();
      }

      if (success && !body) {
        addToast({
          title: "Participante não desvinculado",
          description: body,
          type: "warning",
        });

        clear();
      }

      if (!success) {
        addToast({
          title: "Participante não desvinculado",
          description: body,
          type: "error",
        });

        clear();
      }
    }
  };

  const onVinculeAttendat = async () => {
    try {
      if (!selected) {
        addToast({
          type: "warning",
          title: "Selecione um participante",
          description:
            "Para vincular um participante é necessário selecionar um primeiro",
        });
        setFieldError(true);
        return;
      }

      dispatch(showLoading());
  
      const { status } = await AttendandoViculeService.vincule({
        attendantId: selected,
        hearingId: String(audience?.id),
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: userCustomerProfile?.id,
        logUserId: LogOperation.getUserId(),
      });
  
      if (status) {
        addToast({
          type: "success",
          title: "Vincular participante",
          description: "O participante foi vinculado com sucesso",
        });
  
      await getAttedantListToSelect();

      await getAttedantList();

  
        setOpenDrawer(false);
        dispatch(hideLoading());
        setSelected(null);
        return;
      }
  
      addToast({
        type: "warning",
        title: "Vincular participante",
        description: "Participante já vinculado no procedimento",
      });
  
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro ao vincular participante",
      });
    } finally {
      setSelected(null);
    }
  };

  useEffect(() => {
  }, [listAttendantInHearingFile]);
  
  

  const createAudience = async () => {
    let userId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      userId = user_parsed?.id;
    }

    try {
      if (!nameAudience || !date || !hours || !audienceType) {
        validFields(nameAudience, date, hours, audienceType);
        addToast({
          type: "warning",
          title: "Preencha os campos",
          description: "Para continuar é necessário preencher todos os campos.",
        });
        return dispatch(hideLoading());
      }

      const selectedDate = new Date(date);
      const currentYear = new Date().getFullYear();
      if (selectedDate.getFullYear() < currentYear) {
        addToast({
          type: "warning",
          title: "Data inválida",
          description: "A data selecionada não é valida",
        });
        return;
      }

      const payload = {
        guid: uuidv4(),
        userId: userId,
        active: true,
        placeId: userCustomerProfile?.placeId,
        hearingTypeId: audienceType,
        isClassified: isConfidential,
        expectedDate:
          date && hours ? formatExpectedDate(new Date(date), hours) : null,
        procedureNumber: nameAudience,
        status: "A",
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      const { data, status } = await HearingService.postNewAudience(payload);

      if (status) {
        setNameAudience("");
        updateAudiences({ audienceId: data, notClose: true });
        setErrorFields({
          date: false,
          hours: false,
          nameAudience: false,
          audienceType: false,
        });
        addToast({
          type: "success",
          title: "Audiência criada com sucesso",
        });
        clear();
      } else {
        addToast({
          type: "error",
          title: data,
        });
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Erro ao adicionar audiência",
        description: "Erro ao adicionar audiência",
      });
    }
  };

  const updateAudiences: any = async (props: {
    audienceId?: number;
    notClose?: boolean;
  }) => {
    setLoading(true);
    try {
      if (userCustomerProfile && userCustomerProfile?.placeId) {
        const response = await HearingService.getAllByFilters(
          userCustomerProfile?.placeId
        );

        if (response.status) {
          if (props?.audienceId) {
            response.data.forEach((element: any) => {
              if (element?.id === props?.audienceId) {
                setAudience(element);
              }
            });
          }

          setAudiences(response.data);
          const hearingId = query.get("hearingId");

          if (hearingId) {
            if (hearingId) {
              const audienceFinded = response?.data?.find(
                (x: any) => x.id == hearingId
              );
              setAudience(audienceFinded);
            }
          }
        } else {
          console.error("Erro ao atualizar audiências:", response.message);
        }
      }
    } catch (error) {
      console.error("Erro ao atualizar audiências:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    setAudiences([]);
    setAudience(null);
    setRecord(null);
    setListRecord(null);
    updateAudiences();
  }, [userCustomerProfile]);

  const clearModal = () => {
    setOpenDrawer(false);
    setSelected(null);
    setFieldError(false);
  };

  const clear = () => {
    setIsEdit(false);
    setActive(true);
    setOpenModal(false);
    setOpenModalNotify(false);
    setAudienceType("");
    setNameAudience("");
    setDate("");
    setHours(0);
    setErrorFields({
      date: false,
      hours: false,
      nameAudience: false,
      audienceType: false,
    });
    setEditTypeSelected("");
    setSelectedCustomerAttendantId(undefined);
    setNameAttendant("");
    setAttendantRoleName("");
    setFieldErrorParticipant(false);
    setFieldError(false);
    setFielErrorCreate(false);
    setShowModalConfirmation(false);
    setOpenModalCreate(false);
    setIsConfidential(false);
  };

  const onCloseModalUpdateAudience = () => {
    setNameAudience("");
    setDate(null);
    setAudienceType("");
    setIsConfidential(false);

    setOpenModal(false);
    setIsEdit(false);
    setErrorFields({
      date: false,
      hours: false,
      nameAudience: false,
      audienceType: false,
    });
  };

  const openModalAttedant = () => {
    setOpenDrawer(true);
  };
  const emptyName = stringFormat.isEmptyOrSpaces(nameAttendant);

  const createAttendant = async () => {
    let customerId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      customerId = user_parsed?.customerId;
    }
    const payload = {
      customerId: customerId,
      attendantRoleId: attendantRoleId,
      active: active,
      name: nameAttendant,
      createIn: getActualTime(),
      updateIn: getActualTime(),
    };

    if (emptyName || !attendantRoleId) {
      setFielErrorCreate(emptyName);
      addToast({
        type: "warning",
        title: "Participante",
        description:
          "Para criar um participante é necessário preencher os campos destacados",
      });

      setFieldErrorParticipant(!attendantRoleId);
    } else {
      try {
        await AttendantService.postNewAttendant(payload);
        addToast({
          type: "success",
          title: "Participante cadastrado",
          description: "Participante foi cadastrado com sucesso.",
        });
      } catch (error) {
        addToast({
          type: "error",
          title: "Erro ao adicionar participante",
        });
      } finally {
        getAttedantList();
        clear();
      }
    }
  };

  const onSelectAttendantRemove = (id: number) => {
    setSelectedCustomerAttendantId(id);
    setShowModalConfirmation(true);
  };

  const openModalCleared = () => {
    clear();
    setOpenModal(true);
  };

  const openModalEdit = () => {
    setActive(audience.active);
    if (audience) {
      setOpenModal(true);
      setIsEdit(true);

      const datePart = audience.expectedDate.split("T")[0]; // "2024-02-19"
      const timePart = audience.expectedDate
        .split("T")[1]
        .split(":")
        .slice(0, 2)
        .join(":"); // "14:43"

      setDate(datePart);

      setHours(timePart);
      setAudienceType(audience.hearingTypeId);
      setEditTypeSelected(audience.description);
      setNameAudience(audience.procedureNumber);

      setActive(audience.active);
    }
  };

  //========================================================================================================== DELETE HEARING FILE

  const handleClickDelete = (element: any) => {
    setOpenModalConfirmDelete(true);
    setHearingFileIdToDelete(element.id);
  };

  //========================================================================================================== EDIT HEARING FILE

  const handleClickEdit = (element: IHearingFileBody) => {
    setLoading(true);

    const hearingFileAux: IHearingFileBody = {
      userId: element.userId,
      createIn: element.createIn,
      download: element.download,
      duration: element.duration,
      expectedDate: new Date(element.expectedDate ?? ""),
      fileOrder: element.fileOrder,
      filename: element.filename,
      finishDate: element.finishDate,
      hash: element.hash,
      hearingId: element.hearingId,
      id: element?.id,
      platform: element.platform,
      recordId: element.recordId,
      size: element.size,
      startDate: element.startDate,
      status: element.status,
      title: element.title,
      type: element.type,
      updateIn: new Date(),
      upload: element.upload,
      watermark: element.watermark,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };

    setHearingFileToEdit(hearingFileAux);

    if (hearingFileAux && hearingFileAux.expectedDate) {
      const newDate = format(
        hearingFileAux && hearingFileAux?.expectedDate,
        "yyyy-MM-dd"
      );
      const newTime = format(
        hearingFileAux && hearingFileAux?.expectedDate,
        "HH:mm"
      );
      setDateHearingFile(newDate);
      setTimeHearingFile(newTime.toString());
    }

    setOpenEditRecord(true);
    setLoading(false);
  };

  useEffect(() => {
    if (listRecord && recordEditedReturn !== undefined) {
      updateSchaduledList(
        recordEditedReturn,
        listRecord,
        setListRecord,
        addToast
      );
    }
  }, [recordEditedReturn]);

  //==========================================================================================================
  const onClickSearch = () => {
    filterSearch(
      api,
      userCustomerProfile?.placeId,
      hearingNumber,
      startDate,
      finishDate,
      valueOptionDropDown,
      activeFilter,
      setIsFilter,
      setOpenHearingFilter,
      setAudiences,
      setAudience,
      setLoading,
      addToast,
      setStartDateError,
      setFinishDateError
    );
  };

  const onClickClear = () => {
    clearAudienceFilter(
      api,
      userCustomerProfile?.placeId,
      clearDatesFilter,
      setHearingNumber,
      setStartDate,
      setFinishDate,
      setActiveFilter,
      setValueOptionDropDown,
      setClearDatesFilter,
      setAudiences,
      setIsFilter,
      setAudience,
      setLoading,
      setStartDateError,
      setFinishDateError
    );
  };
  const handleCloseModal = () => {
    setTypeError(false);
    setFileError(false);
    setOpenModalValidation(false);
    setValidateVideo(true);
    setType(null);
    if (!loadingUpload) {
      setFile(null);
      setUploadingFile(false);
    }
  };
  const onCloseModal = () => {
    setHearingNumber("");
    setStartDate("");
    setFinishDate("");
    setValueOptionDropDown("");
    setOpenHearingFilter(false);
  };

  const handleApplyValidationVideo = () => {
    SystemConfigurationService.getVideoAuthenticityValidation(
      file?.name,
      audience?.procedureNumber
    ).then((response) => {
      if (response?.ok) {
        addToast({
          type:
            response.message === "Autenticidade do vídeo não assegurada"
              ? "error"
              : "success",
          title: "autenticação de video",
          description: response.message,
        });
      } else {
        addToast({
          type: "error",
          title: "autenticação de video",
          description: response.message,
        });
      }
      handleCloseModal();
    });
  };

  useEffect(() => {
    if (dataLocation) {
      setAudience(dataLocation);
    }
  }, [dataLocation]);

  useEffect(() => {
    if (file !== null) {
      setUploadingFile(true);
    } else {
      setUploadingFile(false);
    }
  }, [file, uploadingFile]);

  //========================================================================================================== NOTIFY

  const handleClickNotify = async () => {
    const hasNotify = await checkInputsFilled(
      addToast,
      setLoading,
      inputValues
    );

    if (hasNotify) {
      updateStatusHearing(
        audience.id,
        audiences,
        "N",
        setAudiences,
        listRecord,
        setListRecord
      );
      setOpenModalNotify(false);
    }
  };

  //==========================================================================================================

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  //#region web Socket

  const WS_URL_HEARINGS = `${import.meta.env.VITE_APP_WSS_BASE_URL}/hearing`;
  const { sendMessage: sendMessageHearing } = useWebsocket(WS_URL_HEARINGS, {
    onMessage: (event: MessageEvent<any>) => {
      handleChangeHearingsFromWebSocket(event.data, setAudiences);
    },
    onOpen: () => { },
    onClose: () => { },
    shouldReconnect: () => true,
    reconnectInterval: 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const filter = handleHearingFilters();

      const filterHearingsWebSocket = JSON.stringify(filter, (_key, value) => {
        return value === undefined || value === "" ? undefined : value;
      });

      sendMessageHearing(filterHearingsWebSocket);
    }, 10000);

    return () => clearInterval(interval);
  }, [hearingNumber, startDate, finishDate, activeFilter, valueOptionDropDown, userCustomerProfile?.placeId]);

  const handleHearingFilters = () => {
    return {
      placeId: userCustomerProfile?.placeId,
      procedureNumber: hearingNumber,
      startDate: startDate,
      finishDate: finishDate,
      status: valueOptionDropDown?.value,
      active: activeFilter,
    };
  };

  //#endregion

  return (
    <>
      <Container>
        <LoadSpinner loading={loading} zindex={1400} />
        <LeftList
          principalLabel="Audiências"
          audienceSelected={audience}
          onClick={setAudience}
          addClick={openModalCleared}
          placeId={userCustomerProfile?.placeId}
          api={api}
          updateAudiences={updateAudiences}
          audiences={audiences}
          disabledFilter={false}
          isFilter={isFilter}
          filterClick={() => setOpenHearingFilter(true)}
        />

        {audience?.id ? (
          <ListRecorded
            setData={setListRecord}
            recordList={listRecord}
            audienceId={audience?.id}
            selectedRecordId={record?.id}
            setSelectRecord={changeRecord}
            placeId={userCustomerProfile?.placeId}
            addToast={addToast}
            api={api}
            showAddButton={
              !isConfidential || (isConfidential && managerHearingClassified)
            }
            disabled={isConfidential && !managerHearingClassified}
          />
        ) : (
          <ThemeProvider theme={theme(customization)}>
            <AdminDashboard isFull={false} />
          </ThemeProvider>
        )}

        <DrawerDefault
          title={isEdit ? "Editar audiência " : "Criar audiência"}
          openDrawer={openModal}
          setOpenDrawer={() => clear()}
          onClose={() => clear()}
          onApply={isEdit ? editAudience : handleSubmit}
          children={
            <CreateAudience
              errorFields={errorFields}
              active={active}
              date={date}
              time={hours}
              audience={audience}
              onChangeSwitch={() => setActive(!active)}
              onChangeName={setNameAudience}
              procedureNumber={nameAudience}
              onChangeDate={setDate}
              onChangeHours={setHours}
              isConfidential={isConfidential}
              setIsConfidential={setIsConfidential}
              editTypeSelected={editTypeSelected}
              setProcedureTypeId={setAudienceType}
              setObjectType={setObjectType}
              createAudienceMask={createAudienceMask}
              isEdit={isEdit}
            />
          }
        />

        {audience && (
          <RightTemplate
            hearingId={audience?.id}
            onClickValidation={() => setOpenModalValidation(true)}
            hearingNumber={String(audience?.procedureNumber)}
            origin={audience.description ?? "-"}
            startDate={audience.expectedDate}
            endDate={
              audience?.finishDate ? new Date(audience?.finishDate) : new Date()
            }
            onClickRecoding={createHearingRecord}
            data={listRecord}
            navigateTo={`/recording/una?hearingId=${record?.hearingId}&hearingFileId=${record?.id}`}
            navigate={navigate}
            onClick={openModalEdit}
            addButton={openModalAttedant}
            onClickDetach={onSelectAttendantRemove as any}
            addNewAttachment={() => setOpenAttachmentDrawer(true)}
            onClickNotification={() => setOpenModalNotify(true)}
            onClickDownloads={() =>
              handleOpenDrawerDownloads(
                audience ? audience?.id : 0,
                setOpenDownloads,
                setListDownloads,
                setLoading,
                exportHearinDownloadsFiles
              )
            }
            onClickDelete={handleClickDelete}
            onClickEdit={handleClickEdit}
            disabled={isConfidential && !managerHearingClassified}
            listAttendant={listAttendantInHearingFile}
            setListAttendant={setListAttendantInHearingFile}
            listRecord={listRecord?.hearingInProgress}
            record={getFirstRecord(listRecord)}
            audience={audience}
          />
        )}

        <DrawerDefault
          title={"Vincular participante"}
          openDrawer={openDrawer}
          setOpenDrawer={() => clearModal()}
          onClose={() => clearModal()}
          onApply={onVinculeAttendat}
          children={
            <ProcedureAttendant
              setSelected={setSelected}
              selected={selected}
              getAttedantList={getAttedantList}
              attendantList={attendantList}
              modalOpenNewParticipant={() => setOpenModalCreate(true)}
              fieldError={fieldError}
            />
          }
        />

        <DrawerDefault
          title={"Criar participante"}
          openDrawer={openModalCreate}
          setOpenDrawer={() => { }}
          onClose={() => clear()}
          onApply={createAttendant}
          children={
            <CreateEditAttendantInAudience
              onChangeName={setNameAttendant}
              valueEdit={nameAttendant}
              active={active}
              onChangeSwitch={() => setActive(!active)}
              setAttendantRoleDropSelected={setAttendantRoleId}
              attendantRoleName={attendantRoleName}
              hearingTypeId={audience?.hearingTypeId}
              fieldError={fieldErrorCreate}
              fieldErrorParticipant={fieldErrorParticipant}
            />
          }
        />
        <DrawerDefault
          title={"Validar autenticidade do vídeo"}
          openDrawer={openModalValidation}
          setOpenDrawer={() => handleCloseModal()}
          onClose={() => handleCloseModal()}
          onApply={loadingUpload ? () => { } : handleApplyValidationVideo}
          heightBody={84}
          paddingleft={"0" as any}
          paddingright={"0" as any}
          paddingtop={"0" as any}
          disabled={loadingUpload}
          children={
            <ValidationModal
              value={String(audience?.procedureNumber)}
              file={editItem ? { ...file, name: editItem?.filename } : file}
              setFile={setFile}
              editItem={editItem}
              fileError={fileError}
              formatFileSize={formatFileSize}
              setValidateVideo={setValidateVideo}
              setUploadingFile={setUploadingFile}
              uploadingFile={uploadingFile}
            />
          }
        />

        <DrawerDefault
          title={"Notificar"}
          openDrawer={openModalNotify}
          setOpenDrawer={() => handleCloseModal()}
          onClose={() => clear()}
          onApply={() =>
            notify !== undefined && notify === true ? handleClickNotify() : {}
          }
          haveFooter={notify !== undefined ? true : false}
          children={
            <ServiceIntegrationNotification
              inputValues={inputValues}
              setInputValues={setInputValues}
              notify={notify !== undefined ? true : false}
              hearingId={audience?.id}
            />
          }
        />

        <ModalDefault
          openModal={showModalConfirmation}
          setOpenModal={setShowModalConfirmation}
          title={"Desvincular participante"}
          bodyMessageTitle={"Deseja desvincular o participante?"}
          bodyMessageSubTitle={"Esta ação não poderá ser desfeita"}
          textConfirm={"DESVINCULAR"}
          onClickConfirm={onClickDesvincule}
          textCancel={"CANCELAR"}
          bodyMessageMarginBetweenTitleAndSubTitle="30px"
          bodyMessageMarginTop="30px"
          onClickCancel={() => {
            setShowModalConfirmation(false);
          }}
        />

        {record && (
          <RightAudienceComponent
            record={record}
            disableFullView={record.status !== 13}
            title="Detalhes da gravação"
            onClose={() => setRecord(null)}
            container={userCustomerProfile?.containerName}
            baseBlob={baseBlob}
            hearingService={HearingService}
            fullViewNavigateFunction={() =>
              navigate(
                `/audiences/fullview?audienceNumber=${audience.procedureNumber}&hearingFileId=${record.id}&hearingId=${audience.id}&audienceVideo=${record?.filename}`,
                { state: audience }
              )
            }
            hearing={audience}
          />
        )}

        <DownloadsByHearingFile
          emptyMessage="Nenhum arquivo disponível"
          title={"Downloads"}
          tooltipMessage={"Download"}
          openDrawer={openDownloads}
          //listDownloads={loading ? [] : (listDownloads as any)}
          listDownloads={listDownloads}
          setOpenDrawer={setOpenDownloads}
          onApply={() => { }}
          onClose={() => setOpenDownloads(!openDownloads)}
          setItemClicked={(e: any) => handleItemClicked(e, setLoading)}
        />

        <ShowModalDeleteAlert
          api={api}
          hearingId={hearingFileIdToDelete}
          setHearingFileIdToDelete={setHearingFileIdToDelete}
          OpenModalConfirmDelete={openModalConfirmDelete}
          setOpenModalConfirmDelete={setOpenModalConfirmDelete}
          listRecord={listRecord}
          setListRecord={setListRecord}
        />

        <CreateRecordEdit
          api={api}
          openEditRecord={openEditRecord}
          setOpenEditRecord={setOpenEditRecord}
          hearingFileToEdit={hearingFileToEdit}
          date={dateHearingFile}
          setDate={setDateHearingFile}
          time={timeHearingFile}
          setTime={setTimeHearingFile}
          setRecordEditedReturn={setRecordEditedReturn}
        />

        <HearingFilter
          title="Filtrar audiências"
          showDrawer={openHearingFilter}
          setShowDrawer={setOpenHearingFilter}
          hearingNumber={hearingNumber}
          setHearingNumber={setHearingNumber}
          startDate={startDate}
          setStartDate={setStartDate}
          finishDate={finishDate}
          setFinishDate={setFinishDate}
          active={activeFilter}
          setActive={setActiveFilter}
          valueOptionDropDown={valueOptionDropDown}
          setValueOptionDropDown={setValueOptionDropDown}
          onClickSearch={onClickSearch}
          options={options}
          clearDates={clearDatesFilter}
          onClickClear={onClickClear}
          startDateError={startDateError}
          finishDateError={finishDateError}
          onClose={onCloseModal}
        />
        <CreateAttachmentTemplate
          openModal={openAttachmentDrawer}
          setOpenModal={setOpenAttachmentDrawer}
          hearingId={audience?.id}
        />
      </Container>
    </>
  );
};
