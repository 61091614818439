export const stylesGlobalDefault = {
  backgroundListItemColor: "#ECF0F3",
  margintBottomList: "8px",
  inactivedItem: "#A9C3C9",
  activedItem: "#30CD82",
  blue: "#1D9BF0",
  titleNameColor: "#3E424E",
  subTitleNameColor: "#333333",
  titleMenuColor: "#7887A9",
  LabelColor: "#1A1A1A",
  blueDark: "#0D77BF",
  blueLight: "E4F1FA",
  blackMenu1: "#3e424e",
  blackMenu2: "#2b2e38",
  grey_disabled: "#7887A950",
  white: "#FFF",
  black: "#000000",
  gray: "#666666",
  gray2: "#8c8b8b",
  gray3: "#cbcbcb",
  grayLight: "#C8C8C8",
  green: "#4CAF50",
  inactivedButton: "rgba(120, 135, 169, 0.5)",
  inputsBorderFocusOff: "#d1dbe3",
  inputsBorderFocusError: "#EB5861",
  orange: "#E98D0B",

  //"Inter", Arial, Helvetica, sans-serif;
  fontFamily: "Inter",
};
