import React, { useEffect, useRef, useState } from "react";
import { hearingFileService } from "../../../../services/hearingFile";
import { LoadSpinner } from "../../../LoadSpinner";
import { Button } from "@kentarepo/kcf-assets";
import { useToast } from "../../../../hooks/toast";
import {
  TranscriptionFollowUpProps,
  TranscriptionPart,
  Word,
} from "./interface";
import { ButtonContainerCenter, FooterContainerCenter } from "../styles";
import {
  Container,
  ContainerTiny,
  ContentTranscription,
  HighlightedWord,
  NormalWord,
} from "./styles";

const TranscriptionFollowUp: React.FC<TranscriptionFollowUpProps> = ({
  hearingFileId,
  videoRef,
}) => {
  const [transcription, setTranscription] = useState<TranscriptionPart[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const { addToast } = useToast();
  const contentRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    if (!hearingFileId) return;

    setLoading(true);
    const response = await hearingFileService.getTranscriptionByHearingFileId(
      hearingFileId
    );

    if (response.success) {
      setTranscription(response.body);
    } else {
      setError(response.message ?? "");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (transcription.length === 0) fetchData();
  }, [hearingFileId]);

  useEffect(() => {
    if (!videoRef.current) return;

    const video = videoRef.current;

    let animationFrameId: number;

    const updateTime = () => {
      setCurrentTime(video.currentTime);
      animationFrameId = requestAnimationFrame(updateTime);

      // Ajusta a rolagem para manter a palavra visível
      if (contentRef.current) {
        const activeWord = document.querySelector(
          ".highlighted-word"
        ) as HTMLElement;
        if (activeWord instanceof HTMLElement) {
          const parent = contentRef.current;
          const parentHeight = parent.clientHeight;
          const wordPosition = activeWord.offsetTop;

          if (
            wordPosition < parent.scrollTop ||
            wordPosition > parent.scrollTop + parentHeight - 30
          ) {
            parent.scrollTop = wordPosition - parentHeight / 2;
          }
        }
      }
    };

    video.addEventListener("timeupdate", updateTime);

    return () => {
      video.removeEventListener("timeupdate", updateTime);
      cancelAnimationFrame(animationFrameId);
    };
  }, [videoRef]);

  const handleWordClick = (word: Word) => {
    if (videoRef.current) {
      videoRef.current.currentTime = word.begin;
      setCurrentTime(word.begin);
      if (!videoRef.current.paused) {
        videoRef.current.play();
      }
    }
  };

  const handleCopyAllText = async () => {
    try {
      if (transcription && transcription.length !== 0) {
        const result = transcription.map((text) => text.displaytext).join(" ");
        await navigator.clipboard.writeText(result);
        addToast({
          type: "success",
          title: "Texto sincronizado",
          description: "Texto copiado para a área de transferência",
        });
      } else {
        addToast({
          type: "error",
          title: "Texto sincronizado",
          description: "Texto não foi copiado para a área de transferência",
        });
      }
    } catch (err) {
      addToast({
        type: "error",
        title: "Texto sincronizado",
        description: "Texto não foi copiado para a área de transferência",
      });
    }
  };

  return (
    <Container>
      <ContainerTiny>
        {loading ? (
          <LoadSpinner loading={loading} />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <ContentTranscription ref={contentRef}>
            {transcription.map((part) => (
              <p key={part._id}>
                {part.words.map((word: Word, index: number) => {
                  const isActive =
                    currentTime >= word.begin - 0.1 &&
                    currentTime <= word.end + 0.1;
                  return isActive ? (
                    <HighlightedWord
                      className="highlighted-word"
                      key={index}
                      onClick={() => handleWordClick(word)}
                    >
                      {word.word}{" "}
                    </HighlightedWord>
                  ) : (
                    <NormalWord
                      key={index}
                      onClick={() => handleWordClick(word)}
                    >
                      {word.word}{" "}
                    </NormalWord>
                  );
                })}
              </p>
            ))}
          </ContentTranscription>
        )}
      </ContainerTiny>

      <FooterContainerCenter>
        <ButtonContainerCenter>
          <Button
            disabled={transcription.length > 0 ? false : true}
            onClick={transcription.length > 0 ? handleCopyAllText : () => {}}
          >
            {"Copiar"}
          </Button>
        </ButtonContainerCenter>
      </FooterContainerCenter>
    </Container>
  );
};

export default TranscriptionFollowUp;
