import React, { useRef } from 'react';

import Webcam from 'react-webcam';
import { Container } from './styles'

const VideoCamera: React.FC<{ videoConstraints: any }> = ({ videoConstraints }) => {
  const webcamRef = useRef(null)

  return (
    <Container>
      <Webcam
        audio={false}
        height={"200px"}
        ref={webcamRef}
        mirrored
        screenshotQuality={1024}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
        style={{ borderRadius: "10px", objectFit: "cover" }}
      />
    </Container>
  );
}

export default VideoCamera;